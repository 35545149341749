import { gql } from '@apollo/client';

export const COMPETENCE_CALCULATION_ALL_FIELDS = `
    employeeId
    employeeHidden
    permissions
    requiredCompetence
    niceToKnow
    companyCompetenceId
    competenceId
    organisations
    missing
    expired
    expiring
    valid
    expires
    handled {
      handled
      expired
      expiring
      expires
    }
    handledByEmployee {
      handled
      expired
      expiring
      expires
      verified
    }
    certificate {
      valid
      expired
      expiring
      expires
      date
      certificateId
      certificate
    }
    tasks {
      valid
      expired
      expiring
      expires
      verificationId
    }
    gradings {
      valid
      expired
      expiring
      expires
      verificationId
      verificationGradableOptionId
    }
    companyId
    needAdminValidation
    globalCompetencesCalculations
`;

export const GET_COMPANY_REQUIREMENTS_CALCULATIONS = gql`
  query GetData($companyId: Number!) {
    companyRequirementsCalculations(companyId: $companyId) {
      ${COMPETENCE_CALCULATION_ALL_FIELDS}
    }
  }
`;

export const GET_EMPLOYEE_GLOBAL_COMPETENCES_CALCULATIONS = gql`
  query GetData($companyId: Number!, $employeeId: Number!) {
    employeeGlobalCompetencesCalculations(
      companyId: $companyId
      employeeId: $employeeId
    ) {
      ${COMPETENCE_CALCULATION_ALL_FIELDS}
    }
  }
`;

export const GET_EMPLOYEE_COMPANY_FAVORITES_CALCULATIONS = gql`
  query GetData($companyId: Number!, $employeeId: Number!) {
    employeeCompanyFavoritesCalculations(
      companyId: $companyId
      employeeId: $employeeId
    ) {
      ${COMPETENCE_CALCULATION_ALL_FIELDS}
    }
  }
`;

export const GET_USER_CALCULATION = gql`
  query GetData {
    userGlobalCompetencesCalculations {
      ${COMPETENCE_CALCULATION_ALL_FIELDS}
    }
  }
`;

export const GET_USER_COMPANY_CALCULATION = gql`
  query GetData($companyId: Number!) {
    userCompanyRequirementsCalculations(companyId: $companyId) {
      ${COMPETENCE_CALCULATION_ALL_FIELDS}
    }
    userCompanyFavoritesCalculations(companyId: $companyId) {
      ${COMPETENCE_CALCULATION_ALL_FIELDS}
    }
  }
`;

export const getEmployeeCompetenceStatus = (
  isCompanyCompetence: boolean,
  id: number,
  statusData: any,
): any => {
  let competenceStatus = null;
  if (isCompanyCompetence) {
    competenceStatus = statusData.find(
      (status: any) => status.companyCompetenceId === id,
    );
  } else {
    competenceStatus = statusData.find(
      (status: any) => status.competenceId === id,
    );
  }

  return competenceStatus;
};

export const getEmployeeOrganisationStatuses = (
  organisationId: number,
  statusData: any,
): any => {
  const organisationStatus = statusData.filter((status: any) =>
    status.organisations.includes(organisationId),
  );

  return organisationStatus;
};
