import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
  Link,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import useBankID from '../mutations/use-bank-id';

interface BankIDAuthProps {
  collectBankID: Function;
  collectBankIDData: any | undefined;
  collectBankIDError: any | undefined;
  userVisibleData?: any;
  sign?: boolean;
}

const BankIDAuth: FC<BankIDAuthProps> = ({
  collectBankID,
  collectBankIDData,
  collectBankIDError,
  userVisibleData,
  sign,
}) => {
  const { t } = useTranslation();
  const [useQR, setUseQR] = useState(true);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  const {
    startBankID,
    startBankIDData,
    startBankIDLoading,
    startBankIDError,
    signBankID,
    signBankIDData,
    signBankIDLoading,
    signBankIDError,
    cancelBankID,
  } = useBankID();

  React.useEffect(() => {
    if (startBankIDData) {
      if (!useQR) {
        document.location.href = `bankid:///?autostarttoken=${startBankIDData.startBankID.autoStartToken}&redirect=null`;
      }
      collectBankID({
        variables: {
          orderRef: startBankIDData.startBankID.orderRef,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startBankIDData]);

  React.useEffect(() => {
    if (signBankIDData) {
      if (!useQR) {
        document.location.href = `bankid:///?autostarttoken=${signBankIDData.signBankID.autoStartToken}&redirect=null`;
      }
      collectBankID({
        variables: {
          orderRef: signBankIDData.signBankID.orderRef,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signBankIDData]);

  let started = false;

  if (sign) {
    started = signBankIDData && !signBankIDError;
  } else {
    started = startBankIDData && !startBankIDError;
  }

  let autoStartToken = '';
  let orderRef = '';
  if (startBankIDData) {
    autoStartToken = startBankIDData.startBankID.autoStartToken;
    orderRef = startBankIDData.startBankID.orderRef;
  } else if (signBankIDData) {
    autoStartToken = signBankIDData.signBankID.autoStartToken;
    orderRef = signBankIDData.signBankID.orderRef;
  }

  if (started && !collectBankIDData && !collectBankIDError) {
    if (useQR) {
      return (
        <Box mt={1}>
          <Typography>
            Starta BankID-appen på din telefon och tryck på ikonen för QR-kod i
            appen för att skanna QR-koden.
          </Typography>
          <Box display="flex" justifyContent="center" my={3}>
            <QRCode
              size={200}
              value={`bankid:///?autostarttoken=${autoStartToken}`}
              includeMargin
            />
          </Box>
          <Box display="flex" justifyContent="center" my={3}>
            <Link
              style={{ cursor: 'pointer' }}
              component="a"
              color="primary"
              onClick={() => {
                cancelBankID({
                  variables: {
                    orderRef: orderRef,
                  },
                });
              }}>
              {t('Cancel login')}
            </Link>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box mt={1}>
          <Box display="flex" justifyContent="center" mt={3}>
            <CircularProgress size={48} />
          </Box>
          <Box display="flex" justifyContent="center" my={3}>
            <Typography align="center" variant="body1">
              Söker efter BankID...
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" my={3}>
            <Link
              style={{ cursor: 'pointer' }}
              component="a"
              color="primary"
              onClick={() => {
                cancelBankID({
                  variables: {
                    orderRef: orderRef,
                  },
                });
              }}>
              {t('Cancel login')}
            </Link>
          </Box>
        </Box>
      );
    }
  }

  if (mobileDevice) {
    return (
      <>
        <Box>
          <Button
            color="primary"
            fullWidth
            size="large"
            type="button"
            variant="contained"
            disabled={startBankIDLoading || signBankIDLoading}
            style={{ textTransform: 'none' }}
            onClick={() => {
              if (sign) {
                signBankID({
                  variables: {
                    userVisibleData,
                  },
                });
              } else {
                startBankID();
              }
              setUseQR(false);
            }}>
            {t('Open BankID')}
          </Button>
        </Box>
        <Box my={3}>
          <Button
            color="primary"
            fullWidth
            size="large"
            type="button"
            variant="outlined"
            disabled={startBankIDLoading || signBankIDLoading}
            style={{ textTransform: 'none' }}
            onClick={() => {
              if (sign) {
                signBankID({
                  variables: {
                    userVisibleData,
                  },
                });
              } else {
                startBankID();
              }
              setUseQR(true);
            }}>
            {t('Mobile BankID on another device')}
          </Button>
        </Box>
      </>
    );
  }
  return (
    <>
      <Box>
        <Button
          color="primary"
          fullWidth
          size="large"
          type="button"
          variant="contained"
          disabled={startBankIDLoading || signBankIDLoading}
          style={{ textTransform: 'none' }}
          onClick={() => {
            if (sign) {
              signBankID({
                variables: {
                  userVisibleData,
                },
              });
            } else {
              startBankID();
            }
            setUseQR(true);
          }}>
          {t('Mobile BankID on another device')}
        </Button>
      </Box>
      <Box my={3}>
        <Button
          color="primary"
          fullWidth
          size="large"
          type="button"
          variant="outlined"
          disabled={startBankIDLoading || signBankIDLoading}
          style={{ textTransform: 'none' }}
          onClick={() => {
            if (sign) {
              signBankID({
                variables: {
                  userVisibleData,
                },
              });
            } else {
              startBankID();
            }
            setUseQR(false);
          }}>
          {t('Open BankID')}
        </Button>
      </Box>
    </>
  );
};

BankIDAuth.propTypes = {
  collectBankID: PropTypes.func.isRequired,
  collectBankIDData: PropTypes.object,
  collectBankIDError: PropTypes.object,
  userVisibleData: PropTypes.object,
  sign: PropTypes.bool,
};

export default BankIDAuth;
