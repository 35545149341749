export function getProp(objInput: any, desc: string): any {
  if (!desc) {
    return objInput;
  }

  const arr = desc.split('.');
  let obj = objInput;
  try {
    while (arr.length) {
      obj = obj[arr.shift() as string];
    }
  } catch {
    return null;
  }

  return obj;
}

export function setProp(objInput: any, desc: string, value: any) {
  const arr = desc.split('.');
  let obj = objInput;
  while (arr.length > 1) {
    const prop = arr.shift() as string;
    if (!obj[prop]) {
      obj[prop] = {};
    }
    obj = obj[prop];
  }

  obj[arr[0]] = value;

  return obj;
}
