import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '../../icons/Menu';
import Logo from '../logo.component';
import LanguagePopover from '../layout/language-popover.component';
import ThemeIcon from '../layout/theme-icon.component';

interface DocsNavbarProps {
  onSidebarMobileOpen?: () => void;
}

const DocsNavbar: FC<DocsNavbarProps> = ({ onSidebarMobileOpen }) => (
  <AppBar
    sx={{
      backgroundColor: 'background.paper',
      color: 'text.primary',
      boxShadow: 'none',
      borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      zIndex: (theme) => theme.zIndex.drawer + 100,
    }}>
    <Toolbar sx={{ height: 64 }}>
      <IconButton
        color="inherit"
        onClick={onSidebarMobileOpen}
        sx={{
          display: {
            lg: 'none',
          },
        }}>
        <MenuIcon fontSize="small" />
      </IconButton>
      <RouterLink to="/">
        <Logo
          lightBackground
          sx={{
            width: 200,
            display: {
              lg: 'inline',
              xs: 'none',
            },
          }}
        />
      </RouterLink>
      <Box
        sx={{
          flexGrow: 1,
          ml: 2,
        }}
      />
      <LanguagePopover />
      <Box sx={{ ml: 1 }}>
        <ThemeIcon />
      </Box>
    </Toolbar>
  </AppBar>
);

DocsNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DocsNavbar;
