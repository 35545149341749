import { useReactiveVar } from '@apollo/client';
import { filterVar } from '../apollo-client';

export const useFilterMemory = (key: string): any => {
  const filterVarObject = useReactiveVar(filterVar);

  return filterVarObject[key] || {};
};

export const setFilterMemory = (key: string, value: any) => {
  const currentFilters = filterVar();
  currentFilters[key] = value;
  filterVar({ ...currentFilters });
};
