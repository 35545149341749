import type { FC } from 'react';
import type { Theme } from '@mui/material';
import type { SxProps } from '@mui/system';
import darkThemeLogo from '../images/kompetensvy_white.png';
import lightThemeLogo from '../images/kompetensvy_full_white.png';
import lightThemeLogoWithWhiteBackground from '../images/kompetensvy_black.png';
import blueKvBall from '../images/kompetensvyboll.png';
import whiteKvBall from '../images/kompetensvyboll_white.png';
import useTheme from '../hooks/useTheme';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { THEMES } from '../constants';

interface LogoProps {
  sx?: SxProps<Theme>;
  lightBackground?: boolean;
  small?: boolean;
}

const LogoRoot = styled('img')``;

const Logo: FC<LogoProps> = ({ lightBackground, sx, small }) => {
  const { theme } = useTheme();

  let currentLogo = lightThemeLogo;
  if (small) {
    currentLogo = whiteKvBall;
    if (theme === THEMES.DARK) {
      currentLogo = blueKvBall;
    } else if (lightBackground && theme === THEMES.LIGHT) {
      currentLogo = blueKvBall;
    }
  } else {
    if (theme === THEMES.DARK) {
      currentLogo = darkThemeLogo;
    } else if (lightBackground && theme === THEMES.LIGHT) {
      currentLogo = lightThemeLogoWithWhiteBackground;
    }
  }
  return <LogoRoot alt="Logo" sx={sx} src={currentLogo} />;
};

Logo.propTypes = {
  lightBackground: PropTypes.bool,
  small: PropTypes.bool,
};

export default Logo;
