import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CssBaseline,
  IconButton,
  Snackbar,
  ThemeProvider,
  Typography,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useLanguage from './hooks/useLanguage';
import SplashScreen from './components/splash-screen.component';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import useTheme from './hooks/useTheme';
import { getRoutes } from './routes';
import { createCustomTheme } from './theme';
import useCompany from './hooks/useCompany';
import { useLazyQuery, gql } from '@apollo/client';
import useUser from './mutations/use-user';

const App: FC = () => {
  const currentVersion = process.env.REACT_APP_VERSION;
  const [unexpectedVersionOpen, setUnexpectedVersionOpen] = useState(false);
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { user, isInitialized } = useAuth();
  const { company, isInitialized: isCompanyInitialized } = useCompany();
  const content = useRoutes(getRoutes(user, company));
  const { settings } = useSettings();
  const { theme: currentTheme } = useTheme();
  const { logout } = useUser()
  moment.locale(language);

  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: currentTheme,
  });

  // Use useLazyQuery+useEffect to prevent a React Strict Mode warning related to async callbacks on unmountd components.
  // https://github.com/apollographql/apollo-client/issues/6209
  const [getData, { data }] = useLazyQuery(
    gql(`query GetExpectedFrontendVersion {
      expectedFrontendVersion
    }`),
  );

  // Listen for forceLogout custom event emitted from the global error handling on the Apollo client
  // @see: apollo-client.ts --> onError
  useEffect(() => {
    const triggerLogout = async () => {
      await logout('/', false, true)
    }

    // Add event listener on mount
    document.addEventListener('forceLogout', triggerLogout)

    // Remove event listener on unmount
    return () => {
      document.removeEventListener('forceLogout', triggerLogout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // execute query on component mount
  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (data && data.expectedFrontendVersion !== currentVersion) {
      setUnexpectedVersionOpen(true);
    }
  }, [currentVersion, data]);

  const refreshPage = () => {
    window.location.reload();
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setUnexpectedVersionOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      {isInitialized && isCompanyInitialized ? content : <SplashScreen />}
      <Snackbar
        open={unexpectedVersionOpen}
        onClose={handleClose}
        message={
          <>
            <Box maxWidth="300px">
              <Typography variant="h6">{t('New update exists')}</Typography>
              <Typography variant="body2" gutterBottom>
                {t(
                  'Your version of Kompetensvy is not correct and the application may not work as intended.',
                )}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {t(
                  'Try reloading the page, clear the cache or call support for help.',
                )}
              </Typography>
              <Typography variant="body2">
                {t('Your version')}: {currentVersion}
              </Typography>
              <Typography variant="body2">
                {t('Latest version')}: {data?.expectedFrontendVersion}
              </Typography>
            </Box>
            <Box mt={2}>
              <Button variant="contained" onClick={refreshPage}>
                {t('Reload page')}
              </Button>
            </Box>
          </>
        }
        action={action}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      />
    </ThemeProvider>
  );
};

export default App;
