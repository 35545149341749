import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './app';
import reportWebVitals from './reportWebVitals';
import client from './apollo-client';
import './i18n';
import moment from 'moment';
import 'moment/locale/sv';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './msal';

moment.locale('sv'); // it is required to select default locale manually

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ApolloProvider client={client}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <BrowserRouter>
              <MsalProvider instance={msalInstance}>
                <App />
              </MsalProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
