import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  styled,
  TextField,
  Typography,
  Popper,
} from '@mui/material';
import fileUrl from '../../utils/file-url';

interface ModaSelectProps {
  buttonText: string;
  buttonComponent: any;
  value?: any;
  options: any[];
  onSelect: (option: any) => void;
}

const CustomizedPopper = styled(Popper)`
  z-index: 1302 !important;
`;

const ModalSelect: FC<ModaSelectProps> = ({
  buttonText,
  buttonComponent,
  value,
  options,
  onSelect,
  ...other
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>(value);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Box
        component={buttonComponent}
        onClick={handleOpen}
        sx={{ cursor: 'pointer' }}
        {...other}>
        {buttonText}
      </Box>
      <Dialog fullWidth onClose={handleClose} open={open} sx={{ zIndex: 1301 }}>
        <Box
          sx={{
            p: 2,
          }}>
          <Typography color="textPrimary" variant="h6">
            {t('Change company')}
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Autocomplete
              fullWidth
              value={selectedOption}
              onChange={(event, newValue) => {
                setSelectedOption(newValue);
              }}
              disableClearable
              options={options}
              getOptionLabel={(option: any) => option.name}
              renderOption={(props, option: any) => (
                <Box
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}>
                  <img
                    loading="lazy"
                    width="20"
                    src={
                      option.image ? fileUrl(option.image.dataId) : undefined
                    }
                    alt=""
                  />
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} label={t('Company')} />
              )}
              PopperComponent={(props) => <CustomizedPopper {...props} />}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Button
              onClick={() => {
                onSelect(selectedOption);
                handleClose();
              }}
              color="primary"
              fullWidth
              variant="contained">
              {t('Change')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

ModalSelect.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonComponent: PropTypes.any,
};

ModalSelect.defaultProps = {
  value: null,
  buttonComponent: Button,
};

export default ModalSelect;
