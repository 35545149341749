import { useMutation, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GET_USER_SOCIAL_SECURITY_NUMBER } from '../pages/user-account.page/social-security-number';
import toast from 'react-hot-toast';
import useUser from './use-user';

export default function useBankID(): any {
  const { login } = useUser();
  const { t } = useTranslation();

  const [
    startBankID,
    {
      data: startBankIDData,
      loading: startBankIDLoading,
      error: startBankIDError,
    },
  ] = useMutation(
    gql`
      mutation StartBankID {
        startBankID
      }
    `,
    {
      onCompleted() {
        // Complete
      },
      onError(e) {
        toast.error(t(`BankID_${e.message}`));
      },
    },
  );

  const [
    signBankID,
    {
      data: signBankIDData,
      loading: signBankIDLoading,
      error: signBankIDError,
    },
  ] = useMutation(
    gql`
      mutation signBankID($userVisibleData: String!) {
        signBankID(userVisibleData: $userVisibleData)
      }
    `,
    {
      onCompleted() {
        // Complete
      },
      onError(e) {
        toast.error(t(`BankID_${e.message}`));
      },
    },
  );

  const [
    cancelBankID,
    {
      data: cancelBankIDData,
      loading: cancelBankIDLoading,
      error: cancelBankIDError,
    },
  ] = useMutation(
    gql`
      mutation CancelBankID($orderRef: String!) {
        cancelBankID(orderRef: $orderRef)
      }
    `,
    {
      onCompleted() {
        // Complete
      },
      onError(e) {
        toast.error(t(`BankID_${e.message}`));
      },
    },
  );

  const [
    connectBankID,
    {
      data: connectBankIDData,
      loading: connectBankIDLoading,
      error: connectBankIDError,
    },
  ] = useMutation(
    gql`
      mutation ConnectBankID($orderRef: String!) {
        connectBankID(orderRef: $orderRef)
      }
    `,
    {
      update(cache, { data: { connectBankID } }) {
        try {
          if (connectBankID.status === 'complete') {
            const existing = cache.readQuery({
              query: GET_USER_SOCIAL_SECURITY_NUMBER,
            });

            const user = {
              ...(existing as any),
              socialSecurityNumber: connectBankID.message,
            };

            cache.writeQuery({
              query: GET_USER_SOCIAL_SECURITY_NUMBER,
              data: {
                user,
              },
            });
          }
        } catch (e: any) {
          toast.error(e.message);
        }
      },
      onError(e) {
        toast.error(t(`BankID_${e.message}`));
      },
    },
  );

  const [
    signInBankID,
    {
      data: signInBankIDData,
      loading: signInBankIDLoading,
      error: signInBankIDError,
    },
  ] = useMutation(
    gql`
      mutation SignInBankID($orderRef: String!) {
        signInBankID(orderRef: $orderRef)
      }
    `,
    {
      onCompleted({ signInBankID }) {
        if (signInBankID === undefined) {
          return
        }

        if (typeof signInBankID === 'object' && 'status' in signInBankID && signInBankID.status === 'complete') {
          login(signInBankID.message);
        }
      },
      onError(e) {
        toast.error(t(`BankID_${e.message}`));
      },
    },
  );

  const [
    educationCompanyUserAuthenticationBankID,
    {
      data: educationCompanyUserAuthenticationBankIDData,
      loading: educationCompanyUserAuthenticationBankIDLoading,
      error: educationCompanyUserAuthenticationBankIDError,
    },
  ] = useMutation(
    gql`
      mutation EducationCompanyUserAuthenticationBankID(
        $orderRef: String!
        $educationCompanyToken: String!
      ) {
        educationCompanyUserAuthenticationBankID(
          orderRef: $orderRef
          educationCompanyToken: $educationCompanyToken
        )
      }
    `,
    {
      onCompleted() {
        // Complete
      },
      onError(e) {
        toast.error(t(`BankID_${e.message}`));
      },
    },
  );

  const [
    educationCompanySaveBankID,
    {
      data: educationCompanySaveBankIDData,
      loading: educationCompanySaveBankIDLoading,
      error: educationCompanySaveBankIDError,
    },
  ] = useMutation(
    gql`
      mutation EducationCompanySaveBankID(
        $orderRef: String!
        $courseInstance: Object!
      ) {
        educationCompanySaveBankID(
          orderRef: $orderRef
          courseInstance: $courseInstance
        )
      }
    `,
    {
      onCompleted() {
        toast.success(t('Saved'));
      },
      onError(e) {
        toast.error(t(`BankID_${e.message}`));
      },
    },
  );

  return {
    startBankID,
    startBankIDData,
    startBankIDLoading,
    startBankIDError,
    signBankID,
    signBankIDData,
    signBankIDLoading,
    signBankIDError,
    cancelBankID,
    cancelBankIDData,
    cancelBankIDLoading,
    cancelBankIDError,
    connectBankID,
    connectBankIDData,
    connectBankIDLoading,
    connectBankIDError,
    signInBankID,
    signInBankIDData,
    signInBankIDLoading,
    signInBankIDError,
    educationCompanyUserAuthenticationBankID,
    educationCompanyUserAuthenticationBankIDData,
    educationCompanyUserAuthenticationBankIDLoading,
    educationCompanyUserAuthenticationBankIDError,
    educationCompanySaveBankID,
    educationCompanySaveBankIDData,
    educationCompanySaveBankIDLoading,
    educationCompanySaveBankIDError,
  };
}
