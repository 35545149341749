import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
} from '@mui/material';
import Logo from '../../components/logo.component';
import Bankid from './bankid';
import EmailOrPhone from './emailorphone';
import { Helmet } from 'react-helmet-async';
import PublicBanner from '../../components/layout/public-banner';
import MicrosoftSymbol from '../../icons/MicrosoftSymbol';
import useSsoAuth from '../../hooks/useSsoAuth';
import useAuth from '../../hooks/useAuth';
import { LoadingButton } from '@mui/lab';
import useUser from '../../mutations/use-user';

const Login: FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { logout } = useUser();
  const {
    getToken: ssoGetToken,
    loginRedirect: ssoLoginRedirect,
    isAuthenticated: isSsoAuthenticated,
    ssoSignInError,
  } = useSsoAuth();

  let [currentTab, setCurrentTab] = useState<string>('emailorphone');
  let tabs = [
    { value: 'emailorphone', translateLabel: 'Email or phone', label: '' },
    { value: 'bankid', translateLabel: 'BankID', label: '' },
  ];

  tabs.forEach((x: any) => (x.label = t(x.translateLabel)));

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setCurrentTab(value);
  };

  const [ssoLoginInProgress, setSsoLoginInProgress] = useState<boolean>(false)

  /**
   * Handle SSO login button clicks
   */
  const handleSsoLoginClick = async () => {
    // Login is already in progress
    if (ssoLoginInProgress) {
      return
    }

    // Set sso login in progress
    setSsoLoginInProgress(true)

    // Do login redirect if we previously had an error
    if (ssoSignInError) {
      await ssoLoginRedirect(true)
    } else if (!isAuthenticated) {
      // Try to get token if logged in to microsoft
      await ssoGetToken()
    }
  }

  // Setup current tab content component
  const tabContentComponent = currentTab === 'bankid' ? <Bankid /> : <EmailOrPhone />

  // Show feedback when SSO sign in fails
  useEffect(() => {
    if (ssoSignInError) {
      setSsoLoginInProgress(false)

      if (!isAuthenticated && isSsoAuthenticated) {
        logout(false, false, true)
        return
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoSignInError])

  return (
    <>
      <Helmet>
        <title>{t('Log in')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}>
        <PublicBanner />
        <Container maxWidth='sm' sx={{ py: '80px' }}>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}>
                <RouterLink to='/'>
                  <Logo
                    lightBackground
                    sx={{
                      width: '100%',
                      p: '0 24px',
                    }}
                  />
                </RouterLink>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}>
                <Grid
                  container
                  direction='column'
                  alignItems='center'
                  justifyContent='center'>
                  <Grid item xs={12} md={12}>
                    {currentTab && (
                      <Tabs
                        onChange={handleTabsChange}
                        scrollButtons='auto'
                        value={currentTab}
                        variant='scrollable'
                        textColor='primary'>
                        {tabs.map((tab) => (
                          <Tab
                            key={tab.value}
                            label={tab.label}
                            value={tab.value}
                          />
                        ))}
                      </Tabs>
                    )}
                  </Grid>
                  {tabContentComponent}
                </Grid>
              </Box>
              <Divider sx={{ my: 3 }} />
              <LoadingButton
                sx={{ mb: 3 }}
                color='primary'
                size='large'
                type='button'
                variant='outlined'
                startIcon={<MicrosoftSymbol />}
                loadingPosition="start"
                loading={ssoLoginInProgress}
                onClick={handleSsoLoginClick}>
                {t('Sign in with Microsoft')}
              </LoadingButton>
              <Link
                color='textSecondary'
                component={RouterLink}
                to='/register'
                variant='body2'>
                {t('Create new account')}
              </Link>

              <Link
                color='textSecondary'
                component={RouterLink}
                to='/reset-password'
                sx={{ mt: 1 }}
                variant='body2'>
                {t('Forgot password?')}
              </Link>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
