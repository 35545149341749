import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { AppBarProps } from '@mui/material';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './account-popover.component';
import LanguagePopover from './language-popover.component';
import Logo from '../logo.component';
import NotificationsPopover from './notifications-popover.component';
import ThemeIcon from './theme-icon.component';
import NewsIcon from './news-icon.component';

interface NavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const NavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const Navbar: FC<NavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <NavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none',
            },
          }}>
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Box
            sx={{
              display: {
                lg: 'flex',
                xs: 'none',
              },
              alignItems: 'end',
            }}>
            <Logo
              small
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </Box>
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <LanguagePopover />
        <Box sx={{ ml: 1 }}>
          <ThemeIcon />
        </Box>
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <NewsIcon />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </NavbarRoot>
  );
};

Navbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default Navbar;
