import { useReactiveVar } from '@apollo/client';
import { themeVar } from '../apollo-client';

const useTheme = () => {
  const theme = useReactiveVar(themeVar);

  const changeTheme = (newTheme: string): void => {
    localStorage.setItem('theme', newTheme);
    themeVar(newTheme);
  };

  return {
    theme,
    changeTheme,
  };
};

export default useTheme;
