import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser'
import { RedirectRequest } from '@azure/msal-browser/dist/request/RedirectRequest';

export const redirectUri = `${process.env.PUBLIC_URL}/sso-login`
export const postLogoutRedirectUri = `${process.env.PUBLIC_URL}/sso-logout`

export const authConfig = {
    auth: {
        clientId: '5e36c045-cc8e-4ac5-964e-630dff83667b',
        authority: 'https://login.microsoftonline.com/common',
        redirectUri,
        postLogoutRedirectUri,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: false,
    }
}

export const loginRequest: RedirectRequest = {
    scopes: ['openid', 'User.Read', 'profile', 'email'],
}

export const msalInstance = new PublicClientApplication(authConfig)
