import type { FC } from 'react';
import { Box, AppBar } from '@mui/material';
import { styled } from '@mui/material/styles';
import LanguagePopover from './language-popover.component';
import Logo from '../logo.component';
import ThemeIcon from './theme-icon.component';

const NavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const PublicBanner: FC = () => (
  <NavbarRoot
    sx={{
      position: 'static',
      py: 1,
    }}>
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        px: 2,
      }}>
      <Logo
        small
        sx={{
          height: 40,
          width: 40,
        }}
      />
      <Box display="flex">
        <LanguagePopover />
        <Box sx={{ ml: 1 }}>
          <ThemeIcon />
        </Box>
      </Box>
    </Box>
  </NavbarRoot>
);

export default PublicBanner;
