import { useState, FC } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DocsNavbar from './docs-navbar.component';
import DocsSidebar from './docs-sidebar.component';

const DocsLayoutWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 256,
  },
}));

const DocsLayoutContainer = styled('div')({
  flex: '1 1 auto',
  overflow: 'auto',
});

const DocsLayout: FC = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);

  return (
    <>
      <DocsNavbar
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
      />
      <DocsSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <DocsLayoutWrapper>
        <DocsLayoutContainer>
          <Outlet />
        </DocsLayoutContainer>
      </DocsLayoutWrapper>
    </>
  );
};

export default DocsLayout;
