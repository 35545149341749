import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { validator as phoneValidator, normalize } from 'telefonnummer';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormikErrorFocus from '../../components/formik-error-focus.component';
import useUser from '../../mutations/use-user';

const EmailOrPhone: FC = () => {
  const { t } = useTranslation();
  const { signIn, signInLoading, signInError } = useUser();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box mt={2} width="100%">
      <Formik
        initialValues={{
          emailOrPhone: '',
          password: '',
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().max(255).required(t('Password is required')),
          emailOrPhone: Yup.string()
            .max(255)
            .required(t('Email or phone is required')),
        })}
        onSubmit={(values) => {
          let emailOrPhone = values.emailOrPhone;
          if (phoneValidator(emailOrPhone, { onlyMobile: true })) {
            emailOrPhone = normalize(emailOrPhone);
          }

          signIn({
            variables: { emailOrPhone, password: values.password },
          });
        }}>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              error={Boolean(touched.emailOrPhone && errors.emailOrPhone)}
              helperText={touched.password && errors.password}
              name="emailOrPhone"
              onBlur={handleBlur}
              onChange={handleChange}
              label={t('Email or phone number')}
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label={t('Password')}
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              variant="outlined"
              InputProps={{
                endAdornment: values.password.length ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end">
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : undefined,
              }}
            />
            {signInError && (
              <Box mt={3}>
                <FormHelperText error>{signInError.networkError ? t('Technical issue, try again later') : t('Could not log in')}</FormHelperText>
              </Box>
            )}
            <Box mt={2} mb={3}>
              <Button
                color="primary"
                disabled={signInLoading}
                fullWidth
                size="large"
                type="submit"
                variant="contained">
                {t('Sign in now')}
              </Button>
            </Box>
            <FormikErrorFocus />
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default EmailOrPhone;
