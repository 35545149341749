import React, { FC } from 'react';
import { Box } from '@mui/material';
import BankIDAuth from '../../components/bankid-auth.component';
import useBankID from '../../mutations/use-bank-id';

const Bankid: FC = () => {
  const { signInBankID, signInBankIDData, signInBankIDError } = useBankID();

  return (
    <Box mt={4} width="100%">
      <BankIDAuth
        collectBankID={signInBankID}
        collectBankIDData={signInBankIDData}
        collectBankIDError={signInBankIDError}
      />
    </Box>
  );
};

export default Bankid;
