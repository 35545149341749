import { FC, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Link as RouterLink } from 'react-router-dom';
import Page from '../../components/page.component';
import ListView from '../../components/list-view.component';
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client';
import LoadingScreen from '../../components/loading-screen.component';
import CompanyListItem from './company-list-item';
import { selectedResellerVar } from '../../apollo-client';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/page-header.component';

export const GET_RESELLER_COMPANIES = gql`
  query GetResellerCompanies($resellerId: Number!) {
    resellerCompanies(resellerId: $resellerId) {
      id
      name
      image {
        dataId
      }
    }
  }
`;

const AdminCompanies: FC = () => {
  const { t } = useTranslation();
  const resellerId = useReactiveVar(selectedResellerVar);

  // Use useLazyQuery+useEffect to prevent a React Strict Mode warning related to async callbacks on unmountd components.
  // https://github.com/apollographql/apollo-client/issues/6209
  const [getData, { data, loading }] = useLazyQuery(GET_RESELLER_COMPANIES, {
    variables: {
      resellerId,
    },
  });

  // execute query on component mount
  useEffect(() => {
    getData();
  }, [getData]);

  if (!data || loading) {
    return <LoadingScreen />;
  }

  return (
    <Page maxWidth="lg" title="Companies">
      <PageHeader
        title={t('Companies')}
        titleSecondary={t('Companies')}
        crumbs={[{ title: 'Fixa!!', path: '/reseller-dashboard' }]}
        actions={[
          <Button
            component={RouterLink}
            to="/reseller-add-company"
            color="primary"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}>
            {t('Add company')}
          </Button>,
        ]}
      />
      <Box mt={3}>
        <ListView
          data={data.resellerCompanies}
          filterProperties={['name']}
          sortProperties={[
            { label: t('Name (asc)'), value: 'name|asc' },
            { label: t('Name (desc)'), value: 'name|desc' },
          ]}
          listItem={(company: any) => <CompanyListItem company={company} />}
        />
      </Box>
    </Page>
  );
};

export default AdminCompanies;
