import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { CardHeader, CardHeaderProps, useMediaQuery } from '@mui/material';

const SpecialCardHeader: FC<CardHeaderProps> = ({ ...rest }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CardHeader
      sx={{
        '& .MuiCardHeader-action': { marginTop: (theme) => theme.spacing(1) },
        ...(matches
          ? {
              flexDirection: 'column',
              alignItems: 'normal',
            }
          : {}),
      }}
      {...rest}
    />
  );
};

SpecialCardHeader.propTypes = {
  action: PropTypes.any,
};

export default SpecialCardHeader;
