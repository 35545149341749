import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { languageVar } from '../apollo-client';

const useLanguage = () => {
  const { i18n } = useTranslation();
  const language = useReactiveVar(languageVar);

  const changeLanguage = (newLanguage: string): void => {
    localStorage.setItem('language', newLanguage as string);
    i18n.changeLanguage(newLanguage);
    languageVar(newLanguage);
  };

  return {
    language,
    changeLanguage,
  };
};

export default useLanguage;
