/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';
import { ConnectedFocusError } from 'focus-formik-error';

const ErrorFocus: FC = () => {
  return (
    <ConnectedFocusError
      // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
      focusDelay={1}
    />
  );
};

export default ErrorFocus;
