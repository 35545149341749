import { useEffect } from 'react';
import type { FC } from 'react';
import { Badge, IconButton, Tooltip } from '@mui/material';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, gql } from '@apollo/client';

const NewsIcon: FC = () => {
  const { t } = useTranslation();

  const [getUnseenNews, { data }] = useLazyQuery(
    gql`
      query GetData {
        unseenNewsCount
      }
    `,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    getUnseenNews();
  }, [getUnseenNews]);

  return (
    <Tooltip title={t('News') as string}>
      <IconButton
        color="inherit"
        component={RouterLink}
        to={`/news`}
        size="large">
        <Badge color="error" badgeContent={data?.unseenNewsCount}>
          <NewspaperIcon fontSize="small" />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default NewsIcon;
