import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Avatar } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import fileUrl from '../../utils/file-url';

interface ResellerListItemProps {
  reseller: any;
  onClickObject: any;
}

const ResellerListItem: FC<ResellerListItemProps> = ({
  reseller,
  onClickObject,
}) => {
  const navigate = useNavigate();

  let imageSrc = '';
  if (reseller.image) {
    imageSrc = fileUrl(reseller.image.dataId);
  }

  onClickObject.onClick = () => {
    navigate(`/admin-reseller/${reseller.id}`);
  };

  return (
    <Box display="flex" flexGrow={1}>
      <Avatar src={imageSrc} />
      <Box
        minWidth="1px"
        ml={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
        }}>
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{
            minWidth: {
              md: '180px',
            },
            marginRight: 'auto',
          }}>
          {reseller.name}
        </Typography>
        <ArrowForwardIcon fontSize="small" />
      </Box>
    </Box>
  );
};

ResellerListItem.propTypes = {
  reseller: PropTypes.object.isRequired,
  onClickObject: PropTypes.object.isRequired,
};

export default ResellerListItem;
