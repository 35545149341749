import type { FC } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useTranslation } from 'react-i18next';
import useTheme from '../../hooks/useTheme';
import { THEMES } from '../../constants';

const ThemeIcon: FC = () => {
  const { theme, changeTheme } = useTheme();
  const { t } = useTranslation();

  return (
    <Tooltip title={t('Theme') as string}>
      <IconButton
        color="inherit"
        onClick={() => {
          let newTheme = THEMES.LIGHT;
          if (theme === THEMES.LIGHT) {
            newTheme = THEMES.DARK;
          }
          changeTheme(newTheme);
        }}
        size="large">
        {theme === THEMES.LIGHT ? (
          <LightModeIcon fontSize="small" />
        ) : (
          <DarkModeIcon fontSize="small" />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ThemeIcon;
