import { createHttpLink, ApolloClient, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { FALLBACK_LNG, THEMES } from './constants';

export const cache: InMemoryCache = new InMemoryCache();

export const isLoggedInVar = cache.makeVar<boolean>(
  !!localStorage.getItem('token'),
);

export const isSsoLoggedInVar = cache.makeVar<boolean>(
  !!localStorage.getItem('ssoToken'),
);

export const isLogoutInProgressVar = cache.makeVar<boolean>(
  !!localStorage.getItem('logoutInProgress'),
);

export const userOptionsVar = cache.makeVar<any>(
  localStorage.getItem('user-options')
    ? JSON.parse(localStorage.getItem('user-options') as string)
    : {},
);

export const languageVar = cache.makeVar<string>(
  localStorage.getItem('language')
    ? (localStorage.getItem('language') as string)
    : FALLBACK_LNG,
);

export const themeVar = cache.makeVar<string>(
  localStorage.getItem('theme')
    ? (localStorage.getItem('theme') as string)
    : THEMES.LIGHT,
);

export const selectedCompanyVar = cache.makeVar<number>(
  localStorage.getItem('selected-company')
    ? parseInt(localStorage.getItem('selected-company') as string, 10)
    : 0,
);

export const selectedResellerVar = cache.makeVar<number>(
  localStorage.getItem('selected-reseller')
    ? parseInt(localStorage.getItem('selected-reseller') as string)
    : 0,
);

export const filterVar = cache.makeVar<any>({});

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/api/query`,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const isUnauthorizedError = (message: string) => {
  return message === 'Not auth'
}

const isBankIDError = (operationName: string) => {
  const operations = [
    'StartBankID',
    'signBankID',
    'CancelBankID',
    'ConnectBankID',
    'SignInBankID',
    'EducationCompanyUserAuthenticationBankID',
    'EducationCompanySaveBankID'
  ]

  return operations.includes(operationName)
}

enum ApolloErrorType {
  Other = 0,
  Network = 1,
  Authorization = 2,
  BankID = 3
}

let hasUnauthorizedError = false
const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  let currentErrorType = null
  let currentErrorMessage = ''

  // Check/map errors
  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      currentErrorMessage = error.message
      if (isUnauthorizedError(error.message)) {
        currentErrorType = ApolloErrorType.Authorization
      } else if (isBankIDError(operation.operationName)) {
        currentErrorType = ApolloErrorType.BankID
      } else {
        currentErrorType = ApolloErrorType.Other
      }

      return error
    })
  }

  // Network
  if (networkError) {
    console.error(`Network Error: ${networkError.message}`)
    currentErrorType = ApolloErrorType.Network
  }

  // Authorization
  if (currentErrorType === ApolloErrorType.Authorization && !hasUnauthorizedError) {
    hasUnauthorizedError = true
    const forceLogoutEvent = new CustomEvent('forceLogout')
    document.dispatchEvent(forceLogoutEvent)
    return
  }

  // BankID
  if (currentErrorType === ApolloErrorType.BankID) {
    console.error(`BankID error: ${currentErrorMessage}`)
    return
  }

  return forward(operation)
})

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache,
});

export default client;
