import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox } from '@mui/material';

export interface ItemProps {
  children: React.ReactNode;
  onDeselect?: (element: any) => void;
  onSelect?: (element: any) => void;
  selected?: boolean;
  selectable?: boolean;
  element?: any;
}

const Item: FC<ItemProps> = ({
  children,
  onSelect,
  onDeselect,
  selected,
  selectable,
  element,
}) => {
  const handleOnSelect = () => {
    if (onSelect) onSelect(element);
  };
  const handleOnDeselect = () => {
    if (onDeselect) onDeselect(element);
  };

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(2),
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
      }}>
      {selectable && (
        <Box mr={1} display="flex" alignItems="center">
          <Checkbox
            checked={selected}
            onClick={(event) => {
              !selected ? handleOnSelect() : handleOnDeselect();
              event.stopPropagation();
              event.preventDefault();
            }}
          />
        </Box>
      )}
      <Box
        minWidth="1px"
        display="flex"
        flexGrow={1}
        sx={{
          textDecoration: 'none',
        }}>
        {children}
      </Box>
    </Box>
  );
};

Item.propTypes = {
  element: PropTypes.object,
  selectable: PropTypes.bool,
  children: PropTypes.any.isRequired,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

export default Item;
