import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Hidden, Typography } from '@mui/material';
import Breadcrumbs from './custom-breadcrumbs.component';

interface PageHeaderProps {
  title: string;
  titleSecondary?: string;
  actions?: any[] | undefined;
  status?: any;
  crumbs?: any;
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  titleSecondary,
  actions,
  status,
  crumbs,
}) => {
  return (
    <Box>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Breadcrumbs crumbs={crumbs || []} current={titleSecondary || ''} />
        <Hidden smDown>
          <Box display="flex" alignItems="center">
            {actions &&
              actions.map((x, index) => (
                <Box key={index} sx={{ ml: '4px' }}>
                  {x}
                </Box>
              ))}
          </Box>
        </Hidden>
      </Box>
      <Hidden smUp>
        <Box>
          {actions &&
            actions.map((x, index) => (
              <Box key={index} sx={{ mb: '16px' }}>
                {x}
              </Box>
            ))}
        </Box>
      </Hidden>
      <Box>
        <Typography color="textPrimary" variant="h5">
          {title}
        </Typography>
        {status}
      </Box>
    </Box>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  crumbs: PropTypes.array,
  titleSecondary: PropTypes.string,
  actions: PropTypes.array,
  status: PropTypes.any,
};

export default PageHeader;
