import React, { forwardRef, HTMLProps, ReactNode } from 'react';
import { Breakpoint } from '@mui/system';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import useSettings from '../hooks/useSettings';

interface PageProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  title?: string;
  maxWidth?: Breakpoint;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, maxWidth, title = '', ...rest }, ref) => {
    const { settings } = useSettings();
    let actualMaxWidth: any = maxWidth;
    if (!maxWidth) {
      actualMaxWidth = false;
    }
    return (
      <div ref={ref as any} {...rest}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 8,
          }}>
          <Container maxWidth={settings.compact ? actualMaxWidth : false}>
            {children}
          </Container>
        </Box>
      </div>
    );
  },
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  maxWidth: PropTypes.any,
};

export default Page;
