export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};
export const ROLES = {
  SUPER_ADMIN: 'SuperAdmin',
  COMPANY_ADMIN: 'CompanyAdmin',
  RESELLER_ADMIN: 'ResellerAdmin',
  COMPANY_MANAGER: 'CompanyManager',
  NONE: 'None',
  COMPANY_EXECUTIVE: 'CompanyExecutive',
};
export const ROLE_ACCESS = {
  ADD_REMOVE_EMPLOYMENTS: 'AddRemoveEmployments',
  MANAGE_EMPLOYMENT_INFORMATION: 'ManageEmploymentInformation',
  ATTEST_EMPLOYEE_CERTIFICATES: 'AttestEmployeeCertificates',
  MANAGE_EMPLOYMENT_PERMISSIONS: 'ManageEmploymentPermissions',
  ADMINISTRATE_LIBRARY: 'AdministrateLibrary',
  ADMINISTRATE_ORGANISATIONS: 'AdministrateOrganisations',
};
export const FALLBACK_LNG = 'sv';
export const CHART_COLOR = {
  GREEN: 'rgb(67, 160, 71)',
  ORANGE: 'rgb(251, 140, 0)',
  RED: 'rgb(229, 57, 53)',
  GRAY: 'gray'
}
