import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { FALLBACK_LNG } from './constants';
import translationEn from './locales/translation-en';
import translationSv from './locales/translation-sv';

const resources = {
  en: {
    translation: translationEn,
  },
  sv: {
    translation: translationSv,
  },
};

const currentLanguage = localStorage.getItem('language')
  ? (localStorage.getItem('language') as string)
  : FALLBACK_LNG;

i18n.use(initReactI18next).init({
  resources,
  lng: currentLanguage,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
