import { ApolloError, gql, useMutation } from '@apollo/client';
import { AuthError, EventType, InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { loginRequest } from '../msal';
import useUser from '../mutations/use-user';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useSsoAuth = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { login } = useUser();
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [accessToken, setAccessToken] = useState<string|undefined>();
  const [signingIn, setSigningIn] = useState<boolean>(false);

  /**
   * Trigger Azure AD login redirect flow
   */
  const loginRedirect = async (prompt: boolean = false): Promise<void> => {
    const options = prompt ? {...loginRequest, prompt: 'select_account'} : loginRequest
    await instance.loginRedirect(options)
  }

  /**
   * Get token silently
   */
  const getToken = async (): Promise<void> => {
    try {
      // Try to get token silently
      await instance.acquireTokenSilent({
        ...loginRequest,
        prompt: 'none',
        account: instance.getActiveAccount() ?? accounts[0] ?? undefined,
      })
    } catch (e) {
      // Fallback to loginRedirect
      await loginRedirect()
    }
  }

  /**
   * Handle response
   * @param response
   */
  const handleResponse = async (response: any): Promise<void> => {
    if (!signingIn && isValidApplicationId(response) && 'accessToken' in response) {
      setSigningIn(true)
      setAccessToken(response.accessToken)
    }
  }

  /**
   * Check if response contains the correct application id
   * @param response
   */
  const isValidApplicationId = (response: any): boolean => {
    return 'idTokenClaims' in response &&
      'aud' in response.idTokenClaims &&
      response.idTokenClaims.aud === instance.getConfiguration().auth.clientId
  }

  /**
   * Check if sso is in progress
   */
  const isInProgress = (): boolean => {
    return inProgress !== InteractionStatus.None
  }

  /**
   * Check if sso is ready for call
   */
  const shouldGetToken = (): boolean => {
    return !signingIn && isAuthenticated && !isInProgress()
  }

  /**
   * Try to sign in with ssoAccessToken
   */
  const [ssoSignIn, {error: ssoSignInError}] = useMutation(
    gql`
      mutation SsoSignIn($ssoAccessToken: String!) {
        ssoSignIn(ssoAccessToken: $ssoAccessToken)
      }
    `, {
      onCompleted: (queryResponse: any) => {
        const allowedTypes = ['token', 'new_user', 'connect_user']
        if ('ssoSignIn' in queryResponse && 'type' in queryResponse.ssoSignIn && allowedTypes.includes(queryResponse.ssoSignIn.type)) {
          if (queryResponse.ssoSignIn.type === 'new_user' && 'newUser' in queryResponse.ssoSignIn) {
            // New user
            localStorage.setItem('sso-new-user', JSON.stringify(queryResponse.ssoSignIn.newUser))
            navigate('/sso-create-user')
          } else if (queryResponse.ssoSignIn.type === 'connect_user' && 'connectUser' in queryResponse.ssoSignIn) {
            // Connect user
            localStorage.setItem('sso-connect-user', JSON.stringify(queryResponse.ssoSignIn.connectUser))
            navigate('/sso-connect-user')
          } else {
            // Token
            login(queryResponse.ssoSignIn.token, accessToken)
          }
        } else {
          toast.error(t("Couldn't sign you in, try again. If the issue isn't resolved, log out of all your Microsoft accounts before retrying"))
          navigate('/')
        }
      },
      onError: (error: ApolloError) => {
        toast.error(error.toString())
        navigate('/')
      }
    }
  );

  /**
   * Watch for changes to accessToken
   */
  useEffect(() => {
    if (accessToken) {
      ssoSignIn({variables: {ssoAccessToken: accessToken}})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  useEffect(() => {
    // Run on component mount
    const callbackId = instance.addEventCallback(async (message: any) => {
      if (message.eventType === EventType.LOGIN_FAILURE) {
        let errorMessage = t('Login failed, please try again or contact support')
        if (message.error instanceof AuthError && message.error.errorCode === 'access_denied') {
          errorMessage = t('You need to allow access to the Kompetensvy application to be able to log in with Microsoft')
        }

        navigate('/')
        toast.error(errorMessage)
        return
      }

      if (message.eventType === EventType.LOGIN_SUCCESS) {
        await handleResponse(message.payload)
        return
      }

      if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        await handleResponse(message.payload)
        return
      }

      if (message.error) {
        console.error(`Error event type: "${message.eventType}"`)
        console.error(`Error code: "${message.error.errorCode}"`)
        console.error(`Error message: "${message.error.errorMessage}"`)
      }
    })

    return () => {
      // Run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Exposed variables and functions
   */
  return {
    instance,
    accounts,
    inProgress,
    isInProgress: isInProgress(),
    isAuthenticated,
    accessToken,
    setAccessToken,
    loginRedirect,
    getToken,
    isValidApplicationId,
    shouldGetToken,
    ssoSignInError,
  };
}

export default useSsoAuth;
