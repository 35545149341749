import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { isLoggedInVar, cache, isSsoLoggedInVar, isLogoutInProgressVar } from '../apollo-client';
import toast from 'react-hot-toast';
import {
  GET_PENDING_CONNECTION_EMAIL_REQUESTS,
  GET_PENDING_CONNECTION_MOBILE_REQUESTS,
  GET_SENT_PENDING_CONNECTION_USER_REQUESTS,
  GET_RECEIVED_PENDING_CONNECTION_USER_REQUESTS,
} from '../queries';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

export default function useUser() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { instance: ssoInstance, accounts: ssoAccounts } = useMsal();
  const isSsoAuthenticated = useIsAuthenticated();

  /**
   * Log in user
   * @param token
   * @param ssoAccessToken
   */
  const login = (token: string, ssoAccessToken?: string) => {
    // Maybe store SSO token and flag
    if (ssoAccessToken && ssoAccounts.length) {
      localStorage.setItem('ssoToken', ssoAccessToken);
      isSsoLoggedInVar(true);
      ssoInstance.setActiveAccount(ssoAccounts[0]);
    }

    // Save token
    localStorage.setItem('token', token);

    // Set logged in flag
    isLoggedInVar(true);

    // Navigate to root
    navigate('/');
  };

  /**
   * Log out user
   * @param redirect
   * @param clearSettings
   * @param forced
   */
  const logout = async (redirect: string|false = false, clearSettings: boolean = false, forced: boolean = false) => {
    /*
     * Handle SSO logout
     */
    if (isSsoLoggedInVar() || (forced && isSsoAuthenticated)) {
      // Do nothing if logout is already in progress when logout is not forced
      if (isLogoutInProgressVar()) {
        return
      }

      // Set logout in progress variable
      isLogoutInProgressVar(true);

      // Get SSO account
      const account = ssoInstance.getActiveAccount() ?? ssoAccounts[0] ?? null;

      if (account || (!account && isSsoLoggedInVar())) {
        // Logout from SSO if account was found
        if (account) {
          await ssoInstance.logoutRedirect({
            account: account,
            // Prevent logout of microsoft, just perform internal logout (sessionStorage)
            onRedirectNavigate: () => false,
          });
        }

        // Clear sso logged in vars
        clearLoggedInVars(true);

        // Clear logout in progress var
        isLogoutInProgressVar(false);
        localStorage.removeItem('logoutInProgress');

        // Trigger logout again, this time it will skip the SSO part
        await logout('/');

        // Stop
        return;
      }
    }

    /*
     * Handle regular logout
     */

    // Clear logged in
    clearLoggedInVars();

    // Maybe clear settings
    if (clearSettings) {
      localStorage.removeItem('selected-company');
      localStorage.removeItem('selected-reseller');
    }

    // Clear cache
    await cache.reset();

    // Maybe redirect
    if (redirect) {
      window.location.href = redirect;
      return;
    }
  };

  /**
   * Clear logged in var and token
   * @param sso boolean
   */
  const clearLoggedInVars = (sso: boolean = false) => {
    if (sso) {
      isSsoLoggedInVar(false);
      localStorage.removeItem('ssoToken');
    } else {
      isLoggedInVar(false);
      localStorage.removeItem('token');
    }
  };

  const [
    validateUser,
    {
      data: validateUserData,
      loading: validateUserLoading,
      error: validateUserError,
    },
  ] = useMutation(
    gql`
      mutation ValidateUser($email: String!) {
        validateUser(email: $email)
      }
    `,
    {
      onCompleted() {
        // Catch complete
      },
      onError() {
        // Catch errors
      },
    },
  );

  const [signIn, { loading: signInLoading, error: signInError }] = useMutation(
    gql`
      mutation SingnIn($emailOrPhone: String!, $password: String!) {
        signIn(emailOrPhone: $emailOrPhone, password: $password)
      }
    `,
    {
      onCompleted({ signIn }) {
        login(signIn as string);
      },
      onError() {
        //catch errors
      },
    },
  );

  const [
    createUser,
    {
      data: createUserData,
      loading: createUserLoading,
      error: createUserError,
    },
  ] = useMutation(
    gql`
      mutation CreateUser(
        $firstName: String!
        $lastName: String!
        $password: String!
        $url: String!
      ) {
        createUser(
          firstName: $firstName
          lastName: $lastName
          password: $password
          url: $url
        )
      }
    `,
    {
      onCompleted({ createUser }) {
        login(createUser as string);
        navigate('/');
      },
      onError() {
        // Catch errors
      },
    },
  );

  const [
    createEmploymentUser,
    {
      data: createEmploymentUserData,
      loading: createEmploymentUserLoading,
      error: createEmploymentUserError,
    },
  ] = useMutation(
    gql`
      mutation CreateUser($password: String!, $url: String!) {
        createUser(password: $password, url: $url)
      }
    `,
    {
      onCompleted() {
        // Login user
      },
      onError() {
        // Catch errors
      },
    },
  );

  const [
    createSsoUser,
    {
      data: createSsoUserData,
      loading: createSsoUserLoading,
      error: createSsoUserError,
    },
  ] = useMutation(
    gql`
      mutation CreateSsoUser(
        $firstName: String!
        $lastName: String!
        $email: String!
        $azureId: String!
        $companyId: Number!
        $accessToken: String!
      ) {
        createSsoUser(
          firstName: $firstName
          lastName: $lastName
          email: $email
          azureId: $azureId
          companyId: $companyId
          ssoAccessToken: $accessToken 
        )
      }
    `,
    {
      onCompleted({createSsoUser: token}) {
        const userData = localStorage.getItem('sso-new-user');
        if (!userData) {
          navigate('/');
          return;
        }

        const parsedData = JSON.parse(userData);
        if (!('accessToken' in parsedData)) {
          navigate('/');
          return;
        }

        localStorage.removeItem('sso-new-user');
        login(token, parsedData.accessToken);
      },
      onError(error) {
        // Catch errors
        toast.error(error.message.toString());
        localStorage.removeItem('sso-new-user');
        navigate('/');
        return;
      },
    },
  );

  const [
    connectSsoUser,
    {
      data: connectSsoUserData,
      loading: connectSsoUserLoading,
      error: connectSsoUserError,
    },
  ] = useMutation(
    gql`
      mutation ConnectSsoUser(
        $email: String!
        $azureId: String!
        $personId: Number!
        $companyId: Number!
        $accessToken: String!
      ) {
        connectSsoUser(
          email: $email
          azureId: $azureId
          personId: $personId
          companyId: $companyId
          ssoAccessToken: $accessToken 
        )
      }
    `,
    {
      onCompleted({connectSsoUser: token}) {
        const userData = localStorage.getItem('sso-connect-user');
        if (!userData) {
          navigate('/');
          return;
        }

        const parsedData = JSON.parse(userData);
        if (!('accessToken' in parsedData)) {
          navigate('/');
          return;
        }

        localStorage.removeItem('sso-connect-user');
        login(token, parsedData.accessToken);
      },
      onError(error) {
        // Catch errors
        toast.error(error.message.toString());
        localStorage.removeItem('sso-connect-user');
        navigate('/');
        return;
      },
    },
  );

  const [removeUser, { loading: removeUserLoading }] = useMutation(
    gql`
      mutation RemoveUser($id: Number) {
        removeUser(id: $id)
      }
    `,
    {
      onCompleted: async () => {
        await logout('/', true);
        toast.success(t('Your account was successfully removed'));
      },
      onError: (e) => {
        toast.error(e.message);
      },
    },
  );

  const [connectEmail, { loading: connectEmailLoading }] = useMutation(
    gql`
      mutation ConnectEmail($email: String!) {
        connectEmail(email: $email) {
          emailSent {
            email
            date
          }
          userNotified {
            accepted
            connectionType
            connectionString
            date
          }
        }
      }
    `,
    {
      update(cache, { data: { connectEmail } }) {
        if (connectEmail.emailSent) {
          const existing = cache.readQuery({
            query: GET_PENDING_CONNECTION_EMAIL_REQUESTS,
          });
          cache.writeQuery({
            query: GET_PENDING_CONNECTION_EMAIL_REQUESTS,
            data: {
              pendingConnectionEmailRequests: [
                connectEmail.emailSent,
                ...(existing as any).pendingConnectionEmailRequests,
              ],
            },
          });
          toast.success(t('Email sent'));
        } else {
          const existing = cache.readQuery({
            query: GET_SENT_PENDING_CONNECTION_USER_REQUESTS,
          });
          cache.writeQuery({
            query: GET_SENT_PENDING_CONNECTION_USER_REQUESTS,
            data: {
              sentPendingConnectionUserRequests: [
                connectEmail.userNotified,
                ...(existing as any).sentPendingConnectionUserRequests,
              ],
            },
          });
          toast.success(t('Connection request sent to user'));
        }
      },
      onError(e) {
        toast.error(e.message);
      },
    },
  );

  const [deletePendingConnectionEmailRequests] = useMutation(
    gql`
      mutation DeletePendingConnectionEmailRequest($email: String!) {
        deletePendingConnectionEmailRequest(email: $email) {
          email
        }
      }
    `,
    {
      update(cache, { data: { deletePendingConnectionEmailRequest } }) {
        const existing = cache.readQuery({
          query: GET_PENDING_CONNECTION_EMAIL_REQUESTS,
        });
        const { pendingConnectionEmailRequests } = existing as any;
        const newArray = [...pendingConnectionEmailRequests];
        _.remove(
          newArray,
          (x: any) => x.email === deletePendingConnectionEmailRequest.email,
        );

        cache.writeQuery({
          query: GET_PENDING_CONNECTION_EMAIL_REQUESTS,
          data: {
            pendingConnectionEmailRequests: newArray,
          },
        });
        toast.success(t('Email removed'));
      },
      onError(e) {
        toast.error(e.message);
      },
    },
  );

  const [connectMobile, { loading: connectMobileLoading }] = useMutation(
    gql`
      mutation ConnectMobile($number: String!) {
        connectMobile(number: $number) {
          smsSent {
            number
            date
          }
          userNotified {
            accepted
            connectionType
            connectionString
            date
          }
        }
      }
    `,
    {
      update(cache, { data: { connectMobile } }) {
        if (connectMobile.smsSent) {
          const existing = cache.readQuery({
            query: GET_PENDING_CONNECTION_MOBILE_REQUESTS,
          });
          cache.writeQuery({
            query: GET_PENDING_CONNECTION_MOBILE_REQUESTS,
            data: {
              pendingConnectionMobileRequests: [
                connectMobile.smsSent,
                ...(existing as any).pendingConnectionMobileRequests,
              ],
            },
          });
          toast.success(t('SMS sent'));
        } else {
          const existing = cache.readQuery({
            query: GET_SENT_PENDING_CONNECTION_USER_REQUESTS,
          });
          cache.writeQuery({
            query: GET_SENT_PENDING_CONNECTION_USER_REQUESTS,
            data: {
              sentPendingConnectionUserRequests: [
                connectMobile.userNotified,
                ...(existing as any).sentPendingConnectionUserRequests,
              ],
            },
          });
          toast.success(t('Connection request sent to user'));
        }
      },
      onError(e) {
        toast.error(e.message);
      },
    },
  );

  const [deletePendingConnectionMobileRequests] = useMutation(
    gql`
      mutation DeletePendingConnectionMobileRequest($number: String!) {
        deletePendingConnectionMobileRequest(number: $number) {
          number
        }
      }
    `,
    {
      update(cache, { data: { deletePendingConnectionMobileRequest } }) {
        const existing = cache.readQuery({
          query: GET_PENDING_CONNECTION_MOBILE_REQUESTS,
        });
        const { pendingConnectionMobileRequests } = existing as any;
        const newArray = [...pendingConnectionMobileRequests];
        _.remove(
          newArray,
          (x: any) => x.number === deletePendingConnectionMobileRequest.number,
        );

        cache.writeQuery({
          query: GET_PENDING_CONNECTION_MOBILE_REQUESTS,
          data: {
            pendingConnectionMobileRequests: newArray,
          },
        });
        toast.success(t('Mobile removed'));
      },
      onError(e) {
        toast.error(e.message);
      },
    },
  );

  const [acceptConnectUser] = useMutation(
    gql`
      mutation AcceptConnectUser($fromUserId: Number!, $accept: Boolean!) {
        acceptConnectUser(fromUserId: $fromUserId, accept: $accept) {
          accepted
          date
          fromUserId
          fromUser {
            person {
              name
            }
          }
        }
      }
    `,
    {
      update(cache, { data: { acceptConnectUser } }) {
        const existing = cache.readQuery({
          query: GET_RECEIVED_PENDING_CONNECTION_USER_REQUESTS,
        });
        const { receivedPendingConnectionUserRequests } = existing as any;
        const updateThis = receivedPendingConnectionUserRequests.find(
          (x: any) => x.fromUserId === acceptConnectUser.fromUserId,
        );
        updateThis.accepted = acceptConnectUser.accepted;
        cache.writeQuery({
          query: GET_RECEIVED_PENDING_CONNECTION_USER_REQUESTS,
          data: {
            receivedPendingConnectionUserRequests: [
              ...receivedPendingConnectionUserRequests,
            ],
          },
        });
      },
      onError(e) {
        toast.error(e.message);
      },
    },
  );

  const [deletePendingConnectionUserRequests] = useMutation(
    gql`
      mutation DeletePendingConnectionUserRequest($connectionString: String!) {
        deletePendingConnectionUserRequest(
          connectionString: $connectionString
        ) {
          connectionString
        }
      }
    `,
    {
      update(cache, { data: { deletePendingConnectionUserRequest } }) {
        const existing = cache.readQuery({
          query: GET_SENT_PENDING_CONNECTION_USER_REQUESTS,
        });
        const { sentPendingConnectionUserRequests } = existing as any;
        const newArray = [...sentPendingConnectionUserRequests];
        _.remove(
          newArray,
          (x: any) =>
            x.connectionString ===
            deletePendingConnectionUserRequest.connectionString,
        );

        cache.writeQuery({
          query: GET_SENT_PENDING_CONNECTION_USER_REQUESTS,
          data: {
            sentPendingConnectionUserRequests: newArray,
          },
        });
        toast.success(t('User notification removed'));
      },
      onError(e) {
        toast.error(e.message);
      },
    },
  );

  const [
    validateResetPassword,
    {
      data: validateResetPasswordData,
      loading: validateResetPasswordLoading,
      error: validateResetPasswordError,
    },
  ] = useMutation(
    gql`
      mutation ValidateResetPassword($email: String!) {
        validateResetPassword(email: $email)
      }
    `,
    {
      onCompleted() {
        // Catch complete
      },
      onError() {
        // Catch errors
      },
    },
  );

  const [
    resetPassword,
    {
      data: resetPasswordData,
      loading: resetPasswordLoading,
      error: resetPasswordError,
    },
  ] = useMutation(
    gql`
      mutation ResetPassword($password: String!, $url: String!) {
        resetPassword(password: $password, url: $url)
      }
    `,
    {
      onCompleted({ resetPassword }) {
        login(resetPassword as string);
      },
      onError() {
        // Catch errors
      },
    },
  );

  const [
    connectMobileVerified,
    {
      data: connectMobileVerifiedData,
      loading: connectMobileVerifiedLoading,
      error: connectMobileVerifiedError,
    },
  ] = useMutation(
    gql`
      mutation ConnectMobileVerified($url: String!) {
        connectMobileVerified(url: $url)
      }
    `,
    {
      onError() {
        // Catch errors
      },
    },
  );

  const [
    connectEmailVerified,
    {
      data: connectEmailVerifiedData,
      loading: connectEmailVerifiedLoading,
      error: connectEmailVerifiedError,
    },
  ] = useMutation(
    gql`
      mutation ConnectEmailVerified($url: String!) {
        connectEmailVerified(url: $url)
      }
    `,
    {
      onError() {
        // Catch errors
      },
    },
  );

  const [deleteUserEmail, { loading: deleteUserEmailLoading }] = useMutation(
    gql`
      mutation DeleteUserEmail($id: Number!) {
        deleteUserEmail(id: $id) {
          id
          email
        }
      }
    `,
    {
      update(cache, { data: { deleteUserEmail } }) {
        const query = gql`
          query GetData {
            userEmails {
              id
              email
            }
          }
        `;
        const existing = cache.readQuery({
          query,
        });
        const { userEmails } = existing as any;
        const newArray = [...userEmails];
        _.remove(newArray, (x: any) => x.id === deleteUserEmail.id);

        cache.writeQuery({
          query,
          data: {
            userEmails: newArray,
          },
        });
        toast.success(t('User email removed'));
      },
      onError(e) {
        toast.error(e.message);
      },
    },
  );

  const [deleteUserMobile, { loading: deleteUserMobileLoading }] = useMutation(
    gql`
      mutation DeleteUserMobile($id: Number!) {
        deleteUserMobile(id: $id) {
          id
          number
        }
      }
    `,
    {
      update(cache, { data: { deleteUserMobile } }) {
        const query = gql`
          query GetData {
            userMobiles {
              id
              number
            }
          }
        `;
        const existing = cache.readQuery({
          query,
        });
        const { userMobiles } = existing as any;
        const newArray = [...userMobiles];
        _.remove(newArray, (x: any) => x.id === deleteUserMobile.id);

        cache.writeQuery({
          query,
          data: {
            userMobiles: newArray,
          },
        });
        toast.success(t('User mobile number removed'));
      },
      onError(e) {
        toast.error(e.message);
      },
    },
  );

  return {
    login,
    createUser,
    createUserData,
    createUserLoading,
    createUserError,
    validateResetPassword,
    validateResetPasswordData,
    validateResetPasswordLoading,
    validateResetPasswordError,
    resetPassword,
    resetPasswordData,
    resetPasswordLoading,
    resetPasswordError,
    logout,
    clearLoggedInVars,
    signIn,
    signInLoading,
    signInError,
    connectEmail,
    connectEmailLoading,
    connectEmailVerified,
    connectEmailVerifiedData,
    connectEmailVerifiedLoading,
    connectEmailVerifiedError,
    deletePendingConnectionEmailRequests,
    connectMobile,
    connectMobileLoading,
    connectMobileVerified,
    connectMobileVerifiedData,
    connectMobileVerifiedLoading,
    connectMobileVerifiedError,
    deletePendingConnectionMobileRequests,
    acceptConnectUser,
    deletePendingConnectionUserRequests,
    removeUser,
    removeUserLoading,
    deleteUserEmail,
    deleteUserEmailLoading,
    deleteUserMobile,
    deleteUserMobileLoading,
    validateUser,
    validateUserData,
    validateUserLoading,
    validateUserError,
    createEmploymentUser,
    createEmploymentUserData,
    createEmploymentUserLoading,
    createEmploymentUserError,
    createSsoUser,
    createSsoUserData,
    createSsoUserLoading,
    createSsoUserError,
    connectSsoUser,
    connectSsoUserData,
    connectSsoUserLoading,
    connectSsoUserError,
  };
}
