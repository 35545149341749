import { useEffect } from 'react';
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client';
import fileUrl from '../utils/file-url';
import { isLoggedInVar, isLogoutInProgressVar } from '../apollo-client';
import { COMPETENCE_CALCULATION_ALL_FIELDS } from '../calculators/use-calculator';

export const GET_USER_INIT = gql`
query GetPerson {
  user {
    id
    socialSecurityNumber
  }
  userGlobalCompetencesCalculations {
    ${COMPETENCE_CALCULATION_ALL_FIELDS}
  }
  competences {
    id
    name
    description
    image {
      dataId
    }
    verifications {
      verification {
        id
        type
        reoccuring
        amountOfMonths
        specificDateMonth
        specificDateDay
        specificDateAcceptedIfWithinMonthBefore
        validIfOtherVerificationIsValid
        certificateIsValidIfThisIsValid
        hideCertificate
        otherVerificationId
        taskName
        taskDescription
        taskAnswerType
        taskExpires
        files {
          id
          fileInfo {
            id
            name
            size
            type
            dataId
          }
        }
        gradableOptions {
          id
          name
          description
          value
          status
          hidden
        }
        hideGradingAddFromEmployee
        hideGradingFromEmployee
      }
    }
  }
  roles
  userPermissions {
    id
    accepted
    acceptedDate
    addedBy {
      id
      name
    }
    permission {
      id
      companyId
      revoked
      expired
      expiring
      expirationDate
      name
      createdDate
      machineName
      workplace
      tasks
      goodsMaterial
      machineCondition
      gear
      riskAssessment
      locations {
        location {
          id
          name
        }
      }
      addresses {
        id
        name
      }
      files {
        fileInfo {
          id
          name
          size
          type
          dataId
        }
      }
      createdBy {
        id
        name
      }
      machine {
        id
        name
      }
      types {
        machineType {
          name
          image {
            dataId
          }
        }
      }
    }
  }
  userPermissionCalculations {
    companyId
    employeeId
    machineId
    machineTypeIds
    permissionId
    accepted
    waitingForAcceptance
    competencesValid
    expired
    expiring
    expires
    acceptedDate
    expectedCompetenceIds
    missingCompetenceIds
    valid
  }
  employments {
    id
    companyId
    employmentRequirementsCalculations {
      ${COMPETENCE_CALCULATION_ALL_FIELDS}
    }
    company {
      id
      name
      usePermissionCompetences
      image {
        dataId
      }
      competences {
        id
        companyId
        name
        description
        globalCompetences {
          competenceId
        }
        useCertificatesFromGlobalCompetenceId
        image {
          dataId
        }
        verifications {
          verification {
            id
            type
            reoccuring
            amountOfMonths
            specificDateMonth
            specificDateDay
            specificDateAcceptedIfWithinMonthBefore
            validIfOtherVerificationIsValid
            certificateIsValidIfThisIsValid
            hideCertificate
            otherVerificationId
            taskName
            taskDescription
            taskAnswerType
            taskExpires
            files {
              id
              fileInfo {
                id
                name
                size
                type
                dataId
              }
            }
            gradableOptions {
              id
              name
              description
              value
              status
              hidden
            }
            hideGradingAddFromEmployee
            hideGradingFromEmployee
          }
        }
      }
    }
  }
  person {
    id
    name
    firstName
    lastName
    image {
      dataId
    }
    taskAnswers
    certificates {
      id
      competenceId
      certificate {
        id
        date
        createdByPerson
        certificateEndDate
        certificateEducationPlace
        certificateEducationCompanyName
        certificateInstructor
        comment
        files {
          fileInfo {
            id
            name
            size
            type
            dataId
          }
        }
      }
    }
  }
  receivedPendingConnectionUserRequests {
    accepted
    date
    fromUserId
    fromUser {
      person {
        name
      }
    }
  }
  pendingEmployeeConnectionUserRequests {
    id
    accepted
    date
    fromEmploymentCompanyId
    fromEmploymentEmployeeId
    fromEmployment {
      company {
        name
      }
    }
  }
}
`;

const useAuth = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const isLogoutInProgress = useReactiveVar(isLogoutInProgressVar);
  const [getPerson, { data }] = useLazyQuery(GET_USER_INIT);

  // execute query on component mount
  useEffect(() => {
    if (isLoggedIn && !isLogoutInProgress) {
      getPerson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isLogoutInProgress]);

  return {
    platform: 'JWT',
    isAuthenticated: isLoggedIn,
    isInitialized: !isLoggedIn || !!data,
    user: data
      ? {
          id: data.person.id,
          avatar: data.person.image ? fileUrl(data.person.image.dataId) : null,
          name: data.person.name,
          firstName: data.person.firstName,
          lastName: data.person.lastName,
          roles: data.roles,
          userPermissions: data.userPermissions,
          userPermissionCalculations: data.userPermissionCalculations,
          employments: data.employments,
          receivedPendingConnectionUserRequests:
            data.receivedPendingConnectionUserRequests,
          pendingEmployeeConnectionUserRequests:
            data.pendingEmployeeConnectionUserRequests,
          competences: data.competences,
          socialSecurityNumber: data.user.socialSecurityNumber,
        }
      : null,
  };
};

export default useAuth;
