const useSettings = () => {
  const settings: any = {
    compact: true,
    direction: 'ltr',
    responsiveFontSizes: true,
    roundedCorners: true,
  };

  return {
    settings,
  };
};

export default useSettings;
