import { gql } from '@apollo/client';

export const GET_PERSON_PROFILE = gql`
  query GetData {
    person {
      id
      firstName
      lastName
      address
      zipCode
      city
      relativeName
      relativePhone
      phone
      ssgNumber
      id06Number
      image {
        id
        name
        size
        type
        dataId
      }
    }
  }
`;

export const GET_PUBLIC_PERSON_PROFILE = gql`
  query GetData($id: Number!) {
    publicPerson(id: $id) {
      id
      firstName
      lastName
      address
      zipCode
      city
      relativeName
      relativePhone
      phone
      ssgNumber
      id06Number
      image {
        id
        name
        size
        type
        dataId
      }
    }
    competences {
      id
      name
      description
    }
  }
`;

export const GET_PENDING_CONNECTION_EMAIL_REQUESTS = gql`
  query GetData {
    pendingConnectionEmailRequests {
      email
      date
    }
  }
`;

export const GET_PENDING_CONNECTION_MOBILE_REQUESTS = gql`
  query GetData {
    pendingConnectionMobileRequests {
      number
      date
    }
  }
`;

export const GET_SENT_PENDING_CONNECTION_USER_REQUESTS = gql`
  query GetData {
    sentPendingConnectionUserRequests {
      accepted
      connectionType
      connectionString
      date
    }
  }
`;

export const GET_RECEIVED_PENDING_CONNECTION_USER_REQUESTS = gql`
  query GetData {
    receivedPendingConnectionUserRequests {
      accepted
      date
      fromUserId
      fromUser {
        person {
          name
        }
      }
    }
  }
`;

export const GET_PENDING_EMPLOYEE_CONNECTION_USER_REQUESTS = gql`
  query GetData {
    pendingEmployeeConnectionUserRequests {
      accepted
      date
      fromEmploymentCompanyId
      fromEmploymentEmployeeId
      fromEmployment {
        company {
          name
        }
      }
    }
  }
`;

export const GET_COMPANIES = gql`
  query GetCompanies {
    companies {
      id
      name
      image {
        dataId
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query GetCompany($id: Number!) {
    company(id: $id) {
      id
      name
      image {
        dataId
      }
    }
  }
`;

export const GET_COMPANY_PROFILE = gql`
  query GetCompany($id: Number!) {
    company(id: $id) {
      id
      name
      email
      mobilePhone
      phone
      address
      zipCode
      city
      homePage
      contactPerson
      contactPersonPhone
      image {
        dataId
      }
    }
  }
`;

export const GET_COMPANY_EMPLOYMENTS = gql`
  query GetEmployments($companyId: Number!) {
    employments(companyId: $companyId) {
      id
      employeeId
      socialSecurityNumber
      isConnected
      pendingEmployeeConnectionUserRequests {
        accepted
      }
      pendingEmployeeConnectionEmailRequests
      employee {
        id
        name
        firstName
        lastName
        image {
          dataId
        }
      }
    }
  }
`;

export const GET_COMPANY_COMPETENCES = gql`
  query GetCompanyCompetences($companyId: Number!) {
    companyCompetences(companyId: $companyId) {
      id
      companyId
      name
      description
      image {
        id
        name
        size
        type
        dataId
      }
    }
  }
`;

export const GET_COMPANY_LICENSE_FOLDERS = gql`
  query GetCompanyLicenseFolders($companyId: Number!) {
    licenseFolders(companyId: $companyId) {
      id
      name
    }
  }
`;

export const GET_COMPETENCES = gql`
  query GetCompetences {
    competences {
      id
      name
      description
      image {
        id
        name
        size
        type
        dataId
      }
      tags {
        id
        tag {
          id
          name
        }
      }
      verifications {
        verification {
          id
          type
          reoccuring
          amountOfMonths
          specificDateMonth
          specificDateDay
          specificDateAcceptedIfWithinMonthBefore
          validIfOtherVerificationIsValid
          certificateIsValidIfThisIsValid
          hideCertificate
          otherVerificationId
          taskName
          taskDescription
          taskAnswerType
          taskExpires
          files {
            id
            fileInfo {
              id
              name
              size
              type
              dataId
            }
          }
          gradableOptions {
            id
            name
            description
            value
            status
            hidden
          }
          hideGradingAddFromEmployee
          hideGradingFromEmployee
        }
      }
    }
  }
`;

export const GET_COMPETENCE = gql`
  query GetCompetence($id: Number!) {
    competence(id: $id) {
      id
      name
      description
      image {
        id
        name
        size
        type
        dataId
      }
      files {
        fileInfo {
          id
          name
          size
          type
          dataId
        }
      }
      verifications {
        verification {
          id
          type
          reoccuring
          amountOfMonths
          specificDateMonth
          specificDateDay
          specificDateAcceptedIfWithinMonthBefore
          validIfOtherVerificationIsValid
          certificateIsValidIfThisIsValid
          hideCertificate
          otherVerificationId
          taskName
          taskDescription
          taskAnswerType
          taskExpires
          files {
            id
            fileInfo {
              id
              name
              size
              type
              dataId
            }
          }
          gradableOptions {
            id
            name
            description
            value
            status
            hidden
          }
          hideGradingAddFromEmployee
          hideGradingFromEmployee
        }
      }
      tags {
        id
        tag {
          id
          name
        }
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GetData {
    receivedPendingConnectionUserRequests {
      accepted
      date
      fromUserId
      fromUser {
        person {
          name
        }
      }
    }
    pendingEmployeeConnectionUserRequests {
      id
      accepted
      date
      fromEmploymentCompanyId
      fromEmploymentEmployeeId
      fromEmployment {
        company {
          name
        }
      }
    }
    userPermissions {
      id
      accepted
      acceptedDate
      permission {
        id
        revoked
        expired
        expiring
        expirationDate
        name
        machine {
          id
          name
        }
        types {
          machineType {
            name
            image {
              dataId
            }
          }
        }
      }
    }
  }
`;
