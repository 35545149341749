import React, { FC, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { useTheme } from '@mui/material/styles';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Avatar,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Box,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { getProp } from '../utils/object';
import fileUrl from '../utils/file-url';
import { useTranslation } from 'react-i18next';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(
  function ListboxComponent(props, ref) {
    // eslint-disable-next-line react/prop-types
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
      const liChild = child as any;

      if (!liChild.props.children?.props) {
        return 36;
      }

      if (liChild.props.children?.props.children[1].props.secondary) {
        return 60;
      }

      if (liChild.props.children?.props.children[0]) {
        return 48;
      }

      return 36;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}>
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const SelectVirtualized: FC<any> = ({
  options,
  labelProp,
  secondaryLabelFunc,
  label,
  placeholder,
  onChange,
  value,
  error,
  onBlur,
  helperText,
  multiple,
  noSort,
  avatarProps,
  listItemTextFunc,
  groupBy,
  ...rest
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  let optionsData = options;
  if (!noSort) {
    optionsData = _.sortBy(options, [(option) => getProp(option, labelProp)]);
  }

  return (
    <>
      <Autocomplete
        openOnFocus
        groupBy={groupBy}
        renderGroup={renderGroup}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        filterSelectedOptions={multiple}
        fullWidth
        disableListWrap
        sx={{
          boxSizing: 'border-box',
          '& ul': {
            padding: 0,
            margin: 0,
          },
        }}
        ListboxComponent={
          ListboxComponent as React.ComponentType<
            React.HTMLAttributes<HTMLElement>
          >
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder={placeholder ? placeholder : t('Search')}
              error={error}
              helperText={helperText}
            />
          );
        }}
        inputValue={multiple ? query : undefined}
        onInputChange={
          multiple
            ? (event: any, value: string, reason: string) => {
                if (reason === 'input') {
                  setQuery(value);
                }

                // Empty query when selecting an item in the list
                if (reason === 'reset' && event !== null) {
                  setQuery('')
                }
              }
            : undefined
        }
        onClose={
          multiple
            ? (event: any, reason: string) => {
                setQuery('');
              }
            : undefined
        }
        getOptionLabel={(option) => getProp(option, labelProp)}
        renderOption={(props, option) => {
          return (
            <ListItem dense {...props}>
              {!!avatarProps && (
                <ListItemAvatar
                  sx={{ minWidth: 'unset', mr: 2, flexShrink: 0 }}>
                  <Avatar
                    variant="square"
                    src={
                      !!getProp(option, avatarProps)
                        ? fileUrl(getProp(option, avatarProps))
                        : undefined
                    }
                    sx={{
                      height: 20,
                      width: 20,
                    }}
                  />
                </ListItemAvatar>
              )}
              {listItemTextFunc ? (
                listItemTextFunc(option)
              ) : (
                <ListItemText
                  primary={
                    <Box whiteSpace="nowrap" pr={2}>
                      {getProp(option, labelProp)}
                    </Box>
                  }
                  secondary={
                    secondaryLabelFunc ? (
                      <Box whiteSpace="nowrap" pr={2}>
                        {secondaryLabelFunc(option)}
                      </Box>
                    ) : null
                  }
                />
              )}
            </ListItem>
          );
        }}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        options={optionsData}
        {...rest}
      />
    </>
  );
};

SelectVirtualized.propTypes = {
  options: PropTypes.array.isRequired,
  labelProp: PropTypes.string.isRequired,
  secondaryLabelFunc: PropTypes.func,
  avatarProps: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  multiple: PropTypes.bool,
  noSort: PropTypes.bool,
  listItemTextFunc: PropTypes.func,
  groupBy: PropTypes.func,
};

export default SelectVirtualized;
