import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography, Avatar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';
import fileUrl from '../../utils/file-url';

interface CompanyListItemProps {
  company: any;
}

const classes = {
  root: {
    backgroundColor: (theme: any) => theme.palette.background.default,
    padding: (theme: any) => theme.spacing(2),
    borderBottom: (theme: any) => `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    textDecoration: 'none',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  name: {
    minWidth: {
      md: '180px',
    },
    marginRight: 'auto',
  },
  message: {
    maxWidth: 1200,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 'auto',
  },
};

const CompanyListItem: FC<CompanyListItemProps> = ({ company }) => {
  let imageSrc = '';
  if (company.image) {
    imageSrc = fileUrl(company.image.dataId);
  }

  return (
    <>
      <Avatar src={imageSrc} />
      <Box minWidth="1px" ml={1} sx={classes.details}>
        <Typography variant="body2" color="textPrimary" sx={classes.name}>
          {company.name}
        </Typography>
        <IconButton
          component={RouterLink}
          to={`/reseller-company/${company.id}`}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
    </>
  );
};

CompanyListItem.propTypes = {
  company: PropTypes.object.isRequired,
};

export default CompanyListItem;
