import { useEffect } from 'react';
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client';
import fileUrl from '../utils/file-url';
import { isLoggedInVar, isLogoutInProgressVar, selectedCompanyVar } from '../apollo-client';
import { applySort } from '../utils/sort';

const useCompany = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const selectedCompanyId = useReactiveVar(selectedCompanyVar);
  const isLogoutInProgress = useReactiveVar(isLogoutInProgressVar);

  const [getCompany, { data: companyData }] = useLazyQuery(
    gql`
      query GetCompany($id: Number!) {
        company(id: $id) {
          id
          name
          image {
            dataId
          }
          useManagers
          useExecutives
          usePermissions
          useConnectUsers
          useCourseInstances
          useMessages
          useTags
        }
        role(companyId: $id) {
          role
          access
        }
        companyApiConnections(companyId: $id) {
          id
          companyId
          type
          area
          latestRun
        }
      }
    `,
  );

  const setCompany = (companyId: any): void => {
    localStorage.setItem('selected-company', companyId);
    selectedCompanyVar(companyId);
    getCompany({
      variables: { id: companyId },
    });
  };

  // Use useLazyQuery+useEffect to prevent a React Strict Mode warning related to async callbacks on unmountd components.
  // https://github.com/apollographql/apollo-client/issues/6209
  const [getCompanies, { data, loading }] = useLazyQuery(
    gql`
      query GetCompanies {
        companies {
          id
          name
          image {
            dataId
          }
        }
      }
    `,
  );

  // execute query on component mount
  useEffect(() => {
    if (isLoggedIn && !isLogoutInProgress) {
      getCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isLogoutInProgress]);

  useEffect(() => {
    if (data && data.companies.length) {
      let foundCompany = data.companies[0];
      if (selectedCompanyId) {
        const existingCompany = data.companies.find(
          (x: any) => x.id === selectedCompanyId,
        );

        if (existingCompany) {
          foundCompany = existingCompany;
        }
      }
      setCompany(foundCompany.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let selectedCompany: any = null;
  let allCompanies: any = null;
  if (selectedCompanyId && data && data.companies.length) {
    selectedCompany = data.companies.find(
      (x: any) => x.id === selectedCompanyId,
    );
  }

  if (data) {
    allCompanies = applySort(data.companies, 'name|asc');
  }

  return {
    setCompany,
    isInitialized:
      (!data && !loading) ||
      (!!data && (!data.companies.length || !!companyData)),
    companies: allCompanies,
    company: companyData
      ? {
          ...companyData.company,
          avatar: companyData.company.image
            ? fileUrl(companyData.company.image.dataId)
            : null,
          role: companyData.role,
          companyApiConnections: companyData.companyApiConnections,
        }
      : null,
    selectedCompany,
  };
};

export default useCompany;
