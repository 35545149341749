import React, { ChangeEvent, FC, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Box,
  Card,
  Divider,
  InputAdornment,
  Checkbox,
  TablePagination,
  Button,
  Drawer,
  IconButton,
  Hidden,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterListIcon from '@mui/icons-material/FilterList';
import Item from './item';
import { getProp } from '../../utils/object';
import { applySort } from '../../utils/sort';
import SelectVirtualized from '../select-virtualized.component';
import { useTranslation } from 'react-i18next';
import SpecialCardHeader from '../special-card-header.component';
import { useFilterMemory, setFilterMemory } from '../../utils/filter-memory';

interface ListViewProps {
  data: any[];
  filterProperties: any[];
  queryHelpText?: string;
  sortProperties: any[];
  listItem: (element: any, onClick?: any) => JSX.Element;
  selected?: any[] | undefined;
  setSelected?: (element: any) => void | undefined;
  getSelected?: (selected: any, value: any) => boolean;
  action?: any;
  title?: string;
  subtitle?: string;
  hideSearch?: boolean;
  hideSort?: boolean;
  hidePagination?: boolean;
  filters?: any;
  selectedFilters?: any;
  cursorPointer?: boolean;
  hideCard?: boolean;
  filterMemoryKey?: string;
  useFilterDrawer?: boolean;
  headerOptions?: any;
}

const applyFilters = (
  data: any[],
  query: string,
  filterProperties: any[],
): any[] => {
  return data.filter((item) => {
    let matches = true;

    if (query) {
      let containsQuery = false;

      filterProperties.forEach((property) => {
        if (Array.isArray(property)) {
          const itemArray = getProp(item, property[0]);
          if (Array.isArray(itemArray)) {
            itemArray.forEach((arrayItem: any) => {
              const itemProp = getProp(arrayItem, property[1]);
              if (
                itemProp &&
                itemProp.toLowerCase().includes(query.toLowerCase())
              ) {
                containsQuery = true;
              }
            });
          }
        } else {
          const itemProp = getProp(item, property);
          if (
            itemProp &&
            itemProp.toLowerCase().includes(query.toLowerCase())
          ) {
            containsQuery = true;
          }
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (data: any[], page: number, limit: number): any[] => {
  return data.slice(page * limit, page * limit + limit);
};

const ListView: FC<ListViewProps> = ({
  data,
  filterProperties,
  queryHelpText,
  sortProperties,
  listItem,
  selected,
  setSelected,
  getSelected,
  action,
  title,
  subtitle,
  hideSearch,
  hidePagination,
  hideSort,
  filters,
  selectedFilters,
  cursorPointer,
  hideCard,
  filterMemoryKey,
  useFilterDrawer,
  headerOptions,
}) => {
  let existingSelected: any[] = [];
  const { t } = useTranslation();
  if (selected) {
    existingSelected = selected;
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [sort, setSort] = useState<any>(sortProperties[0]);
  const [query, setQuery] = useState<string>('');
  const [filterOpen, setFilterOpen] = useState(false);

  const filterMemory = useFilterMemory(filterMemoryKey || 'NoFilterMemoryKey');

  let currentPage = page;
  let currentRowsPerPage = rowsPerPage;
  let currentSort = sort;
  let currentQuery = query;

  if (filterMemoryKey) {
    currentPage = filterMemory.page || 0;
    currentRowsPerPage = filterMemory.rowsPerPage || 25;
    currentSort = filterMemory.sort || sortProperties[0];
    currentQuery = filterMemory.query || '';
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    if (filterMemoryKey) {
      setFilterMemory(filterMemoryKey, { ...filterMemory, page: newPage });
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const parsedRowsPerPage = parseInt(event.target.value);
    if (filterMemoryKey) {
      setFilterMemory(filterMemoryKey, {
        ...filterMemory,
        rowsPerPage: parsedRowsPerPage,
        page: 0,
      });
    } else {
      setRowsPerPage(parsedRowsPerPage);
      setPage(0);
    }
  };

  const handleSortChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: any,
  ): void => {
    event.persist();
    if (filterMemoryKey) {
      setFilterMemory(filterMemoryKey, { ...filterMemory, sort: value });
    } else {
      setSort(value);
    }
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    if (filterMemoryKey) {
      setFilterMemory(filterMemoryKey, {
        ...filterMemory,
        query: event.target.value,
      });
    } else {
      setQuery(event.target.value);
    }
  };

  const filteredData = applyFilters(data, currentQuery, filterProperties);
  const sortedData = applySort(filteredData, currentSort.value);

  let paginatedData = sortedData;
  if (!hidePagination) {
    paginatedData = applyPagination(
      sortedData,
      currentPage,
      currentRowsPerPage,
    );

    if (sortedData.length && !paginatedData.length) {
      handleChangePage(null, 0);
      currentPage = 0;

      paginatedData = applyPagination(
        sortedData,
        currentPage,
        currentRowsPerPage,
      );
    }
  }

  const onSelected = (array: any) => {
    if (setSelected) {
      return setSelected(array);
    }
  };

  const listIncludesSelected = (list: any[], selected: any) => {
    let includes = false;

    if (getSelected) {
      includes = !!list.find((x: any) => getSelected(x, selected));
    } else {
      includes = !!list.includes(selected);
    }

    return includes;
  };

  const handleSelectAll = (): void => {
    const newSelected = [...existingSelected];
    paginatedData.forEach((element: any) => {
      const includes = listIncludesSelected(newSelected, element);

      if (!includes) {
        newSelected.push(element);
      }
    });
    onSelected(newSelected);
  };

  const handleDeselectAll = (): void => {
    const deselectAll = existingSelected.filter(
      (existing: any) => !listIncludesSelected(paginatedData, existing),
    );
    onSelected(deselectAll);
  };

  const handleSelectOne = (element: any): void => {
    let newSelected = existingSelected;
    if (!listIncludesSelected(existingSelected, element)) {
      newSelected = [...existingSelected, element];
    }
    onSelected(newSelected);
  };

  const handleDeselectOne = (element: any): void => {
    onSelected(
      existingSelected.filter((x: any) => {
        if (getSelected) {
          return !getSelected(x, element);
        }

        return x !== element;
      }),
    );
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) =>
    event.target.checked ? handleSelectAll() : handleDeselectAll();

  const selectable = !!selected && !!setSelected;
  const intersectedSelected = existingSelected.filter((x: any) =>
    listIncludesSelected(paginatedData, x),
  );
  const selectedAll =
    intersectedSelected.length === paginatedData.length &&
    paginatedData.length > 0;
  const selectedSome =
    intersectedSelected.length > 0 &&
    intersectedSelected.length < paginatedData.length;

  const content = (
    <>
      {(!!action || !!title) && (
        <>
          <SpecialCardHeader
            action={action}
            title={title}
            subheader={subtitle}
          />
          <Divider />
        </>
      )}
      {(!hideSearch || !hideSort || (!!filters && !!useFilterDrawer)) && (
        <Box p={1}>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box flexGrow="1" minWidth="300px" p={1}>
              {!hideSearch && (
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={queryHelpText ? queryHelpText : t('Search')}
                  variant="outlined"
                  onChange={handleQueryChange}
                  value={currentQuery}
                />
              )}
            </Box>
            {!hideSort && (
              <>
                <Hidden smUp>
                  <Box minWidth="200px" flexGrow="1" p={1}>
                    <SelectVirtualized
                      fullWidth
                      disableClearable
                      label={t('Sort by')}
                      value={sortProperties.find(
                        (x: any) => x.value === currentSort.value,
                      )}
                      onChange={handleSortChange}
                      options={sortProperties}
                      labelProp="label"
                    />
                  </Box>
                </Hidden>
                <Hidden smDown>
                  <Box minWidth="200px" p={1}>
                    <SelectVirtualized
                      fullWidth
                      disableClearable
                      label={t('Sort by')}
                      value={sortProperties.find(
                        (x: any) => x.value === currentSort.value,
                      )}
                      onChange={handleSortChange}
                      options={sortProperties}
                      labelProp="label"
                    />
                  </Box>
                </Hidden>
              </>
            )}
            {!!filters && !!useFilterDrawer && (
              <Box p={1}>
                <Button
                  variant="contained"
                  onClick={() => setFilterOpen(true)}
                  endIcon={<FilterListIcon />}>
                  {t('Filters')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {!!filters && (
        <>
          {!!useFilterDrawer ? (
            <Drawer
              sx={{
                '& .MuiDrawer-paper': {
                  maxWidth: '380px',
                  width: '100%',
                },
              }}
              anchor="right"
              open={filterOpen}
              onClose={() => setFilterOpen(false)}>
              <Box mt="64px" p={2} display="flex" flexDirection="column">
                <Box>
                  <IconButton onClick={() => setFilterOpen(false)}>
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
                {filters}
              </Box>
            </Drawer>
          ) : (
            <Box p={2} pt={1} display="flex" alignItems="center">
              {filters}
            </Box>
          )}
        </>
      )}
      {selectedFilters}
      {headerOptions}
      {selectable && (
        <Box
          sx={{
            paddingLeft: (theme) => theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
          }}>
          <Box mr={1} display="flex" alignItems="center">
            <Checkbox
              checked={selectedAll}
              indeterminate={selectedSome}
              onChange={handleCheckboxChange}
            />
          </Box>
        </Box>
      )}
      {(!hideSearch || selectable) && <Divider />}
      {paginatedData.map((element: any, index: number) => {
        const onClickObject: any = { onClick: null };
        return (
          <Box
            key={index}
            onClick={() => {
              if (onClickObject.onClick) {
                onClickObject.onClick();
              }
            }}
            style={{
              cursor: cursorPointer ? 'pointer' : 'default',
            }}>
            <Item
              selectable={selectable}
              onDeselect={handleDeselectOne}
              onSelect={handleSelectOne}
              selected={listIncludesSelected(existingSelected, element)}
              element={element}>
              {listItem(element, onClickObject)}
            </Item>
          </Box>
        );
      })}
      {!hidePagination && (
        <TablePagination
          component="div"
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t('of')} ${
              count !== -1 ? count : `more than ${to}`
            }`
          }
          count={sortedData.length}
          page={currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={currentRowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );

  return hideCard ? content : <Card>{content}</Card>;
};

ListView.propTypes = {
  data: PropTypes.array.isRequired,
  filterProperties: PropTypes.array.isRequired,
  queryHelpText: PropTypes.string,
  sortProperties: PropTypes.array.isRequired,
  listItem: PropTypes.func.isRequired,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  getSelected: PropTypes.func,
  action: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hidePagination: PropTypes.bool,
  hideSearch: PropTypes.bool,
  hideSort: PropTypes.bool,
  filters: PropTypes.any,
  selectedFilters: PropTypes.any,
  cursorPointer: PropTypes.bool,
  hideCard: PropTypes.bool,
  filterMemoryKey: PropTypes.string,
  useFilterDrawer: PropTypes.bool,
  headerOptions: PropTypes.any,
};

export default ListView;
