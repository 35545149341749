import _ from 'lodash';
import { getProp } from './object';

export const descendingComparator = (
  a: any,
  b: any,
  orderBy: string,
): number => {
  let aProp = getProp(a, orderBy);
  let bProp = getProp(b, orderBy);

  if (_.isString(aProp)) {
    aProp = aProp.toLocaleLowerCase();
  }
  if (_.isString(bProp)) {
    bProp = bProp.toLocaleLowerCase();
  }

  if (bProp < aProp) {
    return -1;
  }

  if (bProp > aProp) {
    return 1;
  }

  return 0;
};

export const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

export const applySort = (data: any[], sort: string): any[] => {
  const [orderBy, order] = sort.split('|') as [string, 'asc' | 'desc'];
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = data.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};
