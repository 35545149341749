import React, { useEffect } from 'react';
import { FC } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useBankID from '../../mutations/use-bank-id';
import { useLazyQuery, gql } from '@apollo/client';
import LoadingScreen from '../../components/loading-screen.component';
import BankIDAuth from '../../components/bankid-auth.component';

export const GET_USER_SOCIAL_SECURITY_NUMBER = gql`
  query GetData {
    user {
      id
      socialSecurityNumber
    }
  }
`;

const Security: FC = () => {
  const { t } = useTranslation();
  const { connectBankID, connectBankIDData, connectBankIDError } = useBankID();

  // Use useLazyQuery+useEffect to prevent a React Strict Mode warning related to async callbacks on unmountd components.
  // https://github.com/apollographql/apollo-client/issues/6209
  const [getData, { data, loading }] = useLazyQuery(
    GET_USER_SOCIAL_SECURITY_NUMBER,
  );

  // execute query on component mount
  useEffect(() => {
    getData();
  }, [getData]);

  if (!data || loading) {
    return <LoadingScreen />;
  }

  return (
    <Card>
      <CardHeader title={t('Verify social security number')} />
      <Divider />
      {!data.user.socialSecurityNumber ? (
        <>
          <CardContent>
            <Box>
              <Typography variant="body2" color="textSecondary">
                {t(
                  'Verify your social security number. This requires you to accept with BankID. When you have accepted you can use BankID to login instead of a password.',
                )}
              </Typography>
            </Box>
            <Box mt={3} maxWidth="424px">
              <BankIDAuth
                collectBankID={connectBankID}
                collectBankIDData={connectBankIDData}
                collectBankIDError={connectBankIDError}
              />
            </Box>
          </CardContent>
        </>
      ) : (
        <CardContent>
          <Typography>{data.user.socialSecurityNumber}</Typography>
          <Box mt={2}>
            <Typography color="textSecondary" variant="body2">
              {t(
                'You have verified your social security number, you can now use your social security number to login.',
              )}
            </Typography>
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default Security;
