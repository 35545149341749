import { useRef, useState } from 'react';
import type { ElementType, FC } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import PowerIcon from '@mui/icons-material/Power';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import BellIcon from '../../icons/Bell';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

interface Notification {
  title: string;
  description: string;
  type: string;
}

const iconsMap: Record<string, ElementType> = {
  permission: ArtTrackIcon,
  connection: PowerIcon,
  competence: CardMembershipIcon,
};

const NotificationsPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const { user } = useAuth();
  const navigate = useNavigate();

  const notifications: Notification[] = [];

  if (user) {
    let allNotValidNotHandled: any[] = [];
    let allExpiringNotHandled: any[] = [];
    user.employments.forEach((employment: any) => {
      const requiredCompetencesStatus =
        employment.employmentRequirementsCalculations.filter(
          (x: any) => x.competenceId && x.requiredCompetence,
        );
      const requiredCompanyCompetencesStatus =
        employment.employmentRequirementsCalculations.filter(
          (x: any) => x.companyCompetenceId && x.requiredCompetence,
        );

      const requiredCompetences = requiredCompetencesStatus.map(
        (competenceStatus: any) => {
          return {
            competence: user.competences.find(
              (x: any) => x.id === competenceStatus.competenceId,
            ),
            competenceStatus,
          };
        },
      );

      const requiredCompanyCompetences = requiredCompanyCompetencesStatus.map(
        (companyCompetenceStatus: any) => {
          const companyCompetence = employment.company.competences.find(
            (x: any) => x.id === companyCompetenceStatus.companyCompetenceId,
          );

          return {
            competence: companyCompetence,
            competenceStatus: companyCompetenceStatus,
          };
        },
      );

      let allRequiredCompetences = [
        ...requiredCompetences,
        ...requiredCompanyCompetences,
      ];

      const notValidNotHandled = allRequiredCompetences.filter(
        (x) =>
          !x.competenceStatus.valid &&
          !x.competenceStatus.handledByEmployee.handled,
      );

      const expiringNotHandled = allRequiredCompetences.filter(
        (x) =>
          x.competenceStatus.expiring &&
          !x.competenceStatus.handledByEmployee.handled,
      );

      if (notValidNotHandled.length) {
        allNotValidNotHandled = [
          ...allNotValidNotHandled,
          ...notValidNotHandled,
        ];
      }

      if (expiringNotHandled.length) {
        allExpiringNotHandled = [
          ...allExpiringNotHandled,
          ...expiringNotHandled,
        ];
      }
    });

    const permissionsToAccept = user.userPermissions.filter(
      (x: any) => x.accepted !== true && x.accepted !== false,
    );

    if (permissionsToAccept.length) {
      notifications.push({
        description: `${t('You have')} ${permissionsToAccept.length} ${t(
          'permission to handle',
        )}`,
        title: t('Permission'),
        type: 'permission',
      });
    }

    if (user.pendingEmployeeConnectionUserRequests.length) {
      notifications.push({
        description: `${t('You have')} ${
          user.pendingEmployeeConnectionUserRequests.length
        } ${t('employment request to handle')}`,
        title: t('Employment request'),
        type: 'connection',
      });
    }

    if (user.receivedPendingConnectionUserRequests.length) {
      notifications.push({
        description: `${t('You have')} ${
          user.receivedPendingConnectionUserRequests.length
        } ${t('user connect request to handle')}`,
        title: t('User connect request'),
        type: 'connection',
      });
    }

    if (allNotValidNotHandled.length) {
      notifications.push({
        description: `${t('You have')} ${allNotValidNotHandled.length} ${t(
          'not valid competences to handle',
        )}`,
        title: t('Not valid competences'),
        type: 'competence',
      });
    }

    if (allExpiringNotHandled.length) {
      notifications.push({
        description: `${t('You have')} ${allExpiringNotHandled.length} ${t(
          'expiring competences to handle',
        )}`,
        title: t('Expiring competences'),
        type: 'competence',
      });
    }
  }

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t('Notifications') as string}>
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
          size="large">
          <Badge color="error" badgeContent={notifications.length}>
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 },
        }}>
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="h6">
            {t('Notifications')}
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              {t('There are no notifications')}
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification, index) => {
                const Icon = iconsMap[notification.type];

                return (
                  <ListItem divider key={index}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: 'primary.main',
                          color: 'primary.contrastText',
                        }}>
                        <Icon fontSize="small" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Link
                          color="textPrimary"
                          sx={{ cursor: 'pointer' }}
                          underline="none"
                          variant="subtitle2"
                          onClick={() => {
                            navigate('/');
                            setOpen(false);
                          }}>
                          {notification.title}
                        </Link>
                      }
                      secondary={notification.description}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
              }}>
              <Button
                color="primary"
                size="small"
                variant="text"
                onClick={() => {
                  navigate('/');
                  setOpen(false);
                }}>
                {t('See all')}
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
