import React, { FC, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Link as RouterLink } from 'react-router-dom';
import Page from '../../components/page.component';
import ListView from '../../components/list-view.component';
import { useLazyQuery, gql } from '@apollo/client';
import LoadingScreen from '../../components/loading-screen.component';
import ResellerListItem from './reseller-list-item';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/page-header.component';

export const GET_RESELLERS = gql`
  query GetResellers {
    resellers {
      id
      name
      image {
        dataId
      }
    }
  }
`;

const AdminResellers: FC = () => {
  const { t } = useTranslation();
  // Use useLazyQuery+useEffect to prevent a React Strict Mode warning related to async callbacks on unmountd components.
  // https://github.com/apollographql/apollo-client/issues/6209
  const [getData, { data, loading }] = useLazyQuery(GET_RESELLERS);

  // execute query on component mount
  useEffect(() => {
    getData();
  }, [getData]);

  if (!data || loading) {
    return <LoadingScreen />;
  }

  return (
    <Page title={t('Resellers')} maxWidth="lg">
      <PageHeader
        title={t('Resellers')}
        titleSecondary={t('Resellers')}
        crumbs={[]}
        actions={[
          <Button
            component={RouterLink}
            to="/admin-add-reseller"
            color="primary"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}>
            {t('Add reseller')}
          </Button>,
        ]}
      />
      <Box mt={3}>
        <ListView
          filterMemoryKey="AdminResellers"
          data={data.resellers}
          cursorPointer
          filterProperties={['name']}
          sortProperties={[
            { label: t('Name (asc)'), value: 'name|asc' },
            { label: t('Name (desc)'), value: 'name|desc' },
          ]}
          listItem={(reseller: any, onClickObject: any) => (
            <ResellerListItem
              reseller={reseller}
              onClickObject={onClickObject}
            />
          )}
        />
      </Box>
    </Page>
  );
};

export default AdminResellers;
