import { useMutation, gql, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { selectedResellerVar } from '../apollo-client';
import toast from 'react-hot-toast';
import { GET_RESELLERS } from '../pages/admin-resellers.page';
import { GET_RESELLER_COMPANIES } from '../pages/reseller-companies.page';
import { GET_COMPANIES } from '../queries';
import { useTranslation } from 'react-i18next';

export default function useReseller() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const resellerId = useReactiveVar(selectedResellerVar);
  const selectReseller = (selectedResellerId: number) => {
    localStorage.setItem(
      'selected-reseller',
      selectedResellerId as unknown as string,
    );
    selectedResellerVar(selectedResellerId);
  };

  const [addResellerCompany, { loading: addResellerCompanyLoading }] =
    useMutation(
      gql`
        mutation AddCompany(
          $resellerId: Number!
          $name: String!
          $image: Object
          $useManagers: Boolean
          $usePermissions: Boolean
          $usePermissionCompetences: Boolean
          $useConnectUsers: Boolean
          $useMessages: Boolean
          $useTags: Boolean
          $useGradableCompetences: Boolean
        ) {
          addCompany(
            resellerId: $resellerId
            name: $name
            image: $image
            useManagers: $useManagers
            usePermissions: $usePermissions
            usePermissionCompetences: $usePermissionCompetences
            useConnectUsers: $useConnectUsers
            useMessages: $useMessages
            useTags: $useTags
            useGradableCompetences: $useGradableCompetences
          ) {
            id
            name
            image {
              dataId
            }
          }
        }
      `,
      {
        refetchQueries: [
          {
            query: GET_COMPANIES,
          },
          {
            query: GET_RESELLER_COMPANIES,
            variables: {
              resellerId,
            },
          },
        ],
        onCompleted() {
          navigate(-1);
          toast.success(t('Company added'));
        },
        onError(e) {
          toast.error(e.message);
        },
      },
    );

  const [addReseller, { loading: addResellerLoading }] = useMutation(
    gql`
      mutation AddReseller($name: String!, $image: Object) {
        addReseller(name: $name, image: $image) {
          id
          name
          image {
            dataId
          }
        }
      }
    `,
    {
      update(cache, { data: { addReseller } }) {
        try {
          const existing = cache.readQuery({
            query: GET_RESELLERS,
          });
          cache.writeQuery({
            query: GET_RESELLERS,
            data: {
              resellers: [addReseller, ...(existing as any).resellers],
            },
          });
        } catch {
          // No loaded resellers
        }
        navigate(-1);
        toast.success(t('Reseller added'));
      },
      onError(e) {
        toast.error(e.message);
      },
    },
  );

  const [updateReseller, { loading: updateResellerLoading }] = useMutation(
    gql`
      mutation UpdateReseller($id: Number!, $name: String!) {
        updateReseller(id: $id, name: $name) {
          id
          name
        }
      }
    `,
    {
      onCompleted() {
        toast.success(t('Reseller updated'));
      },
      onError(e) {
        toast.error(e.message);
      },
    },
  );

  const [updateResellerImage, updateResellerImageLoading] = useMutation(
    gql`
      mutation UpdateResellerImage($id: Number!, $image: Object) {
        updateResellerImage(id: $id, image: $image) {
          id
          image {
            dataId
          }
        }
      }
    `,
    {
      onCompleted() {
        toast.success(t('Image updated'));
      },
      onError(e) {
        toast.error(e.message);
      },
    },
  );

  const [removeReseller, { loading: removeResellerLoading }] = useMutation(
    gql`
      mutation RemoveReseller($id: Number!) {
        removeReseller(id: $id) {
          id
        }
      }
    `,
    {
      update(cache, { data: { addReseller } }) {
        try {
          const existing = cache.readQuery({
            query: GET_RESELLERS,
          });
          cache.writeQuery({
            query: GET_RESELLERS,
            data: {
              resellers: (existing as any).resellers.filter(
                (x: any) => x.id !== addReseller.id,
              ),
            },
          });
        } catch {
          // No loaded resellers
        }
        navigate('/admin-resellers');
        toast.success(t('Reseller removed'));
      },
      onError(e) {
        toast.error(e.message);
      },
    },
  );

  return {
    selectReseller,
    addResellerCompany,
    addResellerCompanyLoading,
    addReseller,
    addResellerLoading,
    updateReseller,
    updateResellerLoading,
    updateResellerImage,
    updateResellerImageLoading,
    removeReseller,
    removeResellerLoading,
  };
}
