import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface CustomBreadcrumbsProps {
  crumbs: any[];
  current: string;
}

const CustomBreadcrumbs: FC<CustomBreadcrumbsProps> = ({ crumbs, current }) => {
  return (
    <Breadcrumbs
      separator={<ChevronRightIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ mt: 1 }}>
      {crumbs.map((crum: any, index: number) => (
        <Link
          key={index}
          color="textPrimary"
          component={RouterLink}
          to={crum.path}
          variant="subtitle2">
          {crum.title}
        </Link>
      ))}
      <Typography color="textSecondary" variant="subtitle2">
        {current}
      </Typography>
    </Breadcrumbs>
  );
};

CustomBreadcrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired,
  current: PropTypes.string.isRequired,
};

export default CustomBreadcrumbs;
